.bet-content-instant {
    z-index: 1;
    display: flex;
    width: 95%;
    height: 90%;
    animation: fadeIn 0.2s ease-in;
    user-select: none;

    .content {
        width: 75%;
        margin-right: 10px;
        background: rgba(7, 17, 37, 0.7);
        position: relative;
        padding-bottom: 10px;
    }

    .results-info {
        .info {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 10px 0 0 0;

            .tennis-instant-bet-item {
                width: calc(100% / 3 - 1%);
                margin-bottom: 15px;
                box-sizing: border-box;
            }
        }
    }

    .buttons {
        .back {
            background-color: #00ffdd33;
        }
    }

    .betting-accounts {
        margin: 10px;
        > div {
            display: flex;
            margin: auto;
            justify-content: space-between;
            border: 1px solid #74adca;
            padding: 10px;
            box-sizing: border-box;
            font-weight: bold;
            font-size: 0.8vw;
            margin-bottom: 5px;

            .amount {
                color: #ffff00;
            }
        }
    }
}
