@import "../../styles/variables";
$resultBlockHeight: 48px;
$borderRadius: 15px;

.results-block {
  display: flex;
  align-items: center;
  height: $resultBlockHeight;
  margin-top: -32px;
  border-bottom-left-radius: $borderRadius;
  border-bottom-right-radius: $borderRadius;
  background-color: $themeColor2;
  line-height: 28px;
  padding: 5px 5px 4px;

  .competition-name {
    width: 100%;
    height: 30px;
    margin-left: 7px;
    border: 1px solid $themeColor5;
    border-radius: 5px;
    color: #9c9cac;
    margin-top: 1px;
    cursor: default;
    font-weight: bold;
    font-size: 13px;
  }

  .results-button {
    white-space: pre;
    font-size: 12px;
    height: 31px;
    line-height: 26px;
    margin-right: 7px;
    margin-left: 12px;
    padding-left: 15px;
    padding-right: 14px;
    border-radius: 5px;
    color: white;
    border: none;
    outline: none;
    cursor: pointer;
    font-weight: bold;
    text-decoration: none;
    background-color: $asset1;
    box-shadow: inset 0 0 0, inset -2px -5px 4px rgba(0, 0, 0, 0.2);
  }

  .results-button:hover {
    opacity: 0.94;
  }
}