.my-bets-instant {
    margin-top: 10px;
    border-radius: 3px;
    position: relative;
    height: calc(100% - 2vw - 10px);
    
    &-content {
        overflow-y: auto;
        border: 2px solid #054f51;
        padding: 10px;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;

        .won-icon {
            color: #1de41d;
        }
        

        &::-webkit-scrollbar-track {
            background: #151b2c00;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background: #394a60;
            border-radius: 10px;
        }
    }

    &-footer {
        background: linear-gradient(#4fc4b3, #03463d);
        position: absolute;
        bottom: 2px;
        right: 0;
        height: 4vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1vw;
        color: #ffff00;

        &.active {
            cursor: pointer;
        }
    }

    .not-bet-text {
        font-size: 2vw;
        color: #00ffde;
        text-align: center;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
    }
}

.my-bets-instant-item {
    width: calc(100% / 2 - 5px);
    background-color: #74adca66;
    border-radius: 3px 3px 0 0;
    border: 1px solid #00ffde;
    color: #b1c9dc;
    margin-bottom: 5px;
    height: fit-content;
    box-sizing: border-box;
    position: relative;
    

    p {
        margin: 0;
    }

    &:nth-child(odd) {
        margin-right: 5px;
    }

    &:last-child {
        margin-bottom: calc(4vh - 5px);
    }

    &-footer {
        padding: 2px;
        text-align: center;
        border-top: 1px solid #00ffde;
        background-color: #74adcabf;
        min-height: 25px;
        box-sizing: border-box;

        &.active {
            cursor: pointer;
            background: linear-gradient(#4fc4b3, #03463d);
        }
    }

    .state {
        img {
            margin-right: 10px;
        }
    }

    div {
        padding: 12px;

        > p {
            display: flex;
            justify-content: space-between;
            font-size: 15px;
            margin-bottom: 2px;
            height: 20px;

       
            .lost::first-letter {
                color: red;
            }
        }
    }

    &-content {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 0.3vw;
    }

    .my-bets-instant-item-footer,
    .odd {
        color: #ffff00;
    }

    .odd > del {
        margin-left: 10px;
        color: #00ff4a80;
        font-weight: 100;
    }
}
