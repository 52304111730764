@import "../../../../styles/variables";

.my-bet-element {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 3px;
  color: #cccdd9;

  .my-bet-element-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 30px;
    font-size: 12px;
    color: white;
    background-color: $themeColor5;
    opacity: 0.8;
    line-height: 30px;
    padding-left: 10px;
    cursor: pointer;
    user-select: none;

    &:hover {
      opacity: 1;
    }

    .open-close-arrow {
      width: 30px;
      cursor: pointer;
      margin-right: 10px;
      font-size: 14px;
      line-height: 0;
      font-weight: normal;
      color: #bbbcc6;

      .up {
        margin-top: 15px;
        transform: rotate(90deg);
      }

      .down {
        margin-top: 17px;
        transform: rotate(-90deg);
      }
    }
  }

  .my-bet-element-content {
    width: 100%;

    .my-bets-bet-item {
      display: flex;
      height: 110px;
      flex-direction: column;
      background-color: $themeColor3;
      border-top: 1px solid $themeColor5;

      .mb-item-header {
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 7px;
        margin-top: 5px;
        width: 100%;
        height: 50px;

        .mb-time, .mb-id {
          height: 17px;
          margin: 3px 0 0 0;
          font-size: 11px;
          line-height: 15px;
          color: #afb8c9;
        }

        .mb-stadium-name {
          height: 20px;
          font-size: 12px;
          margin-top: 8px;
          color: $alpine;
        }

        .clock-icon {
          position: absolute;
          right: 13px;
          top: 4px;
          width: 14px;
        }
      }

      .mb-type {
        height: 20px;
        font-size: 12px;
        color: #afb8c9;
        text-align: left;
        margin-top: 10px;
        margin-bottom: 5px;
        margin-left: 7px;
      }

      .mb-item-footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        margin-left: 7px;
        width: 96%;
        height: 40px;

        p {
          font-size: 12px;
        }

        .mb-player-name {
          height: 20px;
          font-size: 12px;
          color: $alpine;
          font-weight: bold;
          margin-top: 4px;
        }

        .mb-coef {
          font-weight: bold;
          height: 20px;
          padding-right: 2px;
          font-size: 12px;
          color: #aa8136;
          margin-top: 4px;
        }
      }
    }
  }

  .cash-out-block {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 28px;

    .cash-out-button {
      display: flex;
      width: 98%;
      height: inherit;
      justify-content: space-between;
      padding-left: 8px;
      padding-right: 8px;
      cursor: pointer;
      margin-top: 2px;
      margin-bottom: 2px;
      background-color: $asset2;

      p {
        font-size: 11px;
        line-height: 5px;
        color: white;
      }

      &:hover {
        background-color: $asset3;
      }

      &:active {
        background-color: $asset2;
      }
    }
  }

  .win-info {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    background-color: $themeColor3;
    padding: 2px 7px 2px;
    border-top: 1px solid $themeColor2;

    .container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      line-height: 0;
    }
  }

  -webkit-animation: 0.5s ease 0s normal forwards 1 fadein;
  animation: 0.5s ease 0s normal forwards 1 fadein;
}

@keyframes fadeIn {
  from {opacity: 0}
  to {opacity: 1}
}