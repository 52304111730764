@import "./styles/variables";

.app {
  min-width: 900px;
}

.app-loader {
  width: 65px;
  height: 65px;
  display: block;
  position: absolute;
  top: 30%;
  left: 45%;
  border-radius: 50%;
  border-top: 3px solid $asset1;
  border-right: 3px solid transparent;
  background: transparent;
  -webkit-animation: 0.7s spin linear infinite;
  animation: 0.7s spin linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}