@import "../../../styles/variables";

.banner-container {
  display: block;

  .banner {
    width: 100%;
    margin-top: 4px;
    cursor: pointer;
    border-bottom: 1px solid $themeColor1;

    &:hover {
      border-bottom: 1px solid $asset1;
    }

    .banner-text {
      width: 80px;
      height: 30px;
      margin-top: 0;
      margin-left: 40%;
      position: absolute;
    }

    .sport-name {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 0;
      margin-top: 20px;
      letter-spacing: 1.5px;

      &.football {
        font-size: 1.5vw;
        margin-top: 7px;
      }

      &.tennis {
        font-size: 2vw;
      }

      &.horse {
        font-size: 1.9vw;
      }

      &.greyhounds {
        margin-top: 25px;
        font-size: 1.3vw;
      }
    }

    .start-time {
      width: max-content;;
      font-size: 14px;
      font-weight: bold;
      color: #fab64d;
      letter-spacing: 1px;
      margin-top: 0;

      &.football {
        font-size: 1vw;
      }

      &.tennis {
        font-size: 1vw;
        margin-left: 4px;
      }

      &.horse {
        font-size: 1vw;
      }

      &.greyhounds {
        font-size: 0.9vw;
      }
    }

    img {
      display: flex;
      width: inherit;
    }
  }
}