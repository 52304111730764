.tennis-instant-bet-item {
  background-color: #74adca52;
  border-radius: 3px 3px 0 0;
  border: 1px solid #00ffde;
  position: relative;
  padding: 8px;
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: 10px;
  }

  .ti-close-button {
    position: absolute;
    cursor: pointer;
    right: 10px;
  }

  .title {
    color: #00ffde;
  }

  .winner-name,
  .title {
    font-size: 0.9vw;
  }

  .max-bet-btn {
    font-size: 12px;
    line-height: 1;
  }
  
  .wining-amount{
    font-size: 12px;
  }

  .bet-amount-error {
    font-size: 13px;
    color: #d95b5b;
    font-weight: bold;
  }

  p {
    margin: 0 0 3px 0;
    display: flex;
    justify-content: space-between;
  }

  form {
    margin-bottom: 5px;
    display: flex;
    align-items: flex-start;

    input {
      background-color: #ffffff4d;
      border: none;
      width: 90%;
      height: 1.1vw;
      border-radius: 10px 0 0 10px;
      outline: none;
      padding: 0 10px;
      box-sizing: border-box;
      color: #ffff;
    }

    button {
      border-radius: 0 10px 10px 0;
      background-color: #09796ab3;
      border: 1px solid #00ffde;
      color: #ffff;
      width: 20%;
      height: 1.1vw;
      cursor: pointer;
    }
  }
}
