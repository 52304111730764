@import "../../../../styles/variables";

$radius: 2px;

.market-tab-menu {
  display: flex;
  width: calc(100% + 2px);
  border-radius: $radius;
  justify-content: space-between;

  .market-tabs {
    display: flex;
    width: 100%;

    .market-tab {
      display: flex;
      width: 100%;
      justify-content: center;
      border-radius: $radius;
      background-color: $themeColor4;
      margin-right: 2px;
      margin-left: 2px;
      cursor: pointer;
      font-size: 13px;
      line-height: 32px;
      color: #9ea1a9;
    }

    .market-tab-menu-bookmark {
      width: 60px;
      padding: 0 15px 0 15px;
      font-size: 22px;
      color: $alpine;

      &.active-tab {
        color: #b18636;
      }

      .bookmarks-count {
        width: 16px;
        height: 16px;
        font-size: 10px;
        border-radius: 50px;
        background-color: rgba(117, 117, 117, 0.5);
        margin-top: 5px;
        line-height: 15px;
        color: white;
        position: absolute;
        left: 34px;
      }
    }

    .market-tab:hover {
      background-color: $themeColor2;

      @if & {
        &.market-tab-menu-bookmark {
          color: $alpine;
        }
      }
    }

    .active-tab, .active-tab:hover {
      color: white;
      background-color: $themeColor2;
      border-bottom: 2px solid $marketGroupUnderline;
    }

    .active-tab:hover {
      cursor: default;
    }
  }
}