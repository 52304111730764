.pop-up {
    position: absolute;
    background: #0000005e;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 111;

    .pop-up-body {
        height: 22vw;
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 1111;
        transform: translate(-50%, -50%);
        &.history-pop-up {
            width: 70%;
            background-color: #000000ad;
            box-shadow: 1px 1px 7px black;
            padding: 1.5vw 1vw;
            box-sizing: border-box;
        }

        img {
            width: 50%;
        }
    }

    .close-button {
        position: absolute;
        right: 5px;
        top: 5px;
        color: #ffffff6b;
        cursor: pointer;
    }

    &-body {
        text-align: center;
        width: 100%;
        height: 100%;

        video {
            width: 100%;
            height: 100%;
        }
    }

    .pop-up-text,
    .winningAmount {
        margin: 0;
        color: #00ffde;
        font-size: 1.5vw;
    }

    .winningAmount {
        font-weight: bold;
        margin-top: 15px;
        position: absolute;
        bottom: 18%;
        left: 48%;
        transform: rotate(-16deg);
        color: black;
    }
}
