@import "../../../styles/variables";

$fontColor: #b6b7bd;

.history-pop-up {
  cursor: default;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  font-weight: bold;
  color: $fontColor;
  background-color: rgba(0, 0, 0, 0.7);
  animation: fadeIn 0.13s linear;
  z-index: 20;

  .pop-up-inner {
    width: 80%;
    height: 80%;
    flex-direction: column;
    display: flex;
    margin-top: 50px !important;
    margin-left: auto;
    margin-right: auto;
    background: $themeColor2;

    .pop-up-header {
      border-bottom: 2px solid $myBetsLine;

      p {
        padding-right: 19px;
        border-right: 1px solid;
      }
    }

    .history-pop-up-container {
      display: flex;
      flex-direction: column;
      font-size: 13px;

      .history-tabs {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        background-color: $themeColor4;

        .history-tab {
          height: 28px;
          margin-top: 10px;
          cursor: pointer;
          line-height: 28px;
          padding-right: 25px;
          padding-left: 25px;
          text-align: center;
          border-right: 2px solid $themeColor2;

          &.active {
            background-color: $themeColor2;
            cursor: default;

            &:hover {
              color: $fontColor;
            }
          }

          &:hover {
            color: white;
          }
        }
      }

      select, option {
        font-weight: bold;
      }

      .history-filters {
        display: flex;
        width: 100%;
        float: left;
        background-color: $themeColor2;
        justify-content: center;
        font-size: 13px;
        padding-bottom: 10px;

        .filters {
          display: flex;
          width: 100%;
        }

        .dynamic-filters {
          display: flex;
          width: 100%;
          margin-left: -7px;

          &.disabled {
            opacity: 0.4;
            pointer-events: none;

            .history-filter {
              user-select: none;
            }
          }
        }

        .history-filter {
          width: 25%;
          margin-left: 10px;

          p {
            text-align: left;
            height: 10px;
          }

          &.filter-bet-range {
            width: 30%;
            margin-right: 5.5%;
          }

          .filter-calendars {
            display: flex;
            width: 115%;

            .search-calendar {
              display: flex;
              width: 90%;
              height: 30px;
              background-color: $themeColor3;
              line-height: 30px;
              text-align: left;
              padding-left: 10px;
              justify-content: space-between;
              border: 1px solid $themeColor5;;
              border-radius: 0;
              margin-top: 2px;

              input {
                font-weight: bold;
                font-size: 12px;
                color: #7c8292 !important;
                background-color: $themeColor3;
              }

              img {
                opacity: 0.7;
              }

              .react-datepicker-wrapper .react-datepicker__input-container {
                width: 107%;
              }

              .react-datepicker__triangle {
                visibility: hidden;
              }

              .react-datepicker__month-container {
                border-radius: 5px;
              }

              .react-datepicker, .react-datepicker__header {
                border: none;
                background-color: $themeColor3;
                color: white;
              }

              .react-datepicker__day, .react-datepicker__current-month, .react-datepicker__day-name {
                color: #969db0;
                border-radius: 0;
              }

              .react-datepicker__day--disabled {
                color: #494e5c;
              }

              .react-datepicker__day:hover {
                background-color: #2E323E;
                border-radius: 0;
              }
              
              .react-datepicker__day--keyboard-selected{
                background-color: unset;
              }
              
              .react-datepicker__day--selected {
                background-color: $themeColor2;
              }

              .calendar-input {
                width: 100%;
                border: none;
                background-color: $themeColor3;
                color: $fontColor;
                cursor: pointer;
              }

              .calendar-icon {
                width: 16px;
                height: 16px;
                float: left;
                margin-right: 8px;
                margin-top: 6px;
                pointer-events: none;
              }
            }
          }

          .bet-id-input {
            width: 96%;
            margin: 2px 0 3px 0;
            box-sizing: border-box;
            background: $themeColor3;
            border: none;
            height: 32px;
            float: left;
            box-shadow: 0 0 0 1px $themeColor5 inset;
            font-size: 12px;
            line-height: 30px;
            color: #c1c4cd;
            padding: 0 10px;
            outline: none;
          }
        }

        .custom-select {
          border-radius: 0;
          border: 1px solid $themeColor5;
          box-sizing: border-box;
          margin-top: 2px;

          select {
            background: $themeColor3;
            cursor: pointer !important;
            border-radius: 0 !important;
          }
        }

        .show-button {
          display: flex;
          align-items: center;
          justify-content: center;
          max-width: 150px !important;
          min-width: 110px;
          right: 10px;
          float: right;
          height: 31px;
          padding-left: 10px;
          padding-right: 10px;
          line-height: 30px;
          margin-top: 38px;
          margin-right: 12px;
          font-size: 13px;
          cursor: pointer;
          color: white;
          background-color: $asset2;

          &:hover {
            opacity: 0.9;
          }

          .loading-icon {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            border-top: 2px solid white;
            border-right: 1px solid transparent;
            background: transparent;
            -webkit-animation: 0.5s spin linear infinite;
            animation: 0.5s spin linear infinite;
          }
        }
      }
    }

    .empty-history {
      margin-top: 55px;
      font-size: 15px;
    }

    .history-data-loader {
      width: 48px;
      height: 48px;
      display: block;
      position: absolute;
      top: 35%;
      left: 48%;
      border-radius: 50%;
      border-top: 3px solid $asset1;
      border-right: 3px solid transparent;
      background: transparent;
      -webkit-animation: 0.5s spin linear infinite;
      animation: 0.5s spin linear infinite;
    }

    .bets-list-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      max-height: 77%;

      .bets-list-titles {
        display: flex;
        width: 100%;
        height: 26px;
        line-height: 6px;
        flex-direction: row;
        font-size: 13px;
        background-color: $themeColor3;

        .bets-list-title {
          width: 10%;
          padding-left: 5px;
          padding-right: 35px;
          line-height: 26px;
          font-size: 12px;
          text-align: center;

          &.data-and-id {
            width: 15%;
            padding-right: 0;
            padding-left: 0;
          }

          &.bet-type {
            width: 40%;
            padding-left: 0;
            text-align: center;
          }
        }
      }

      .bets-list {
        overflow-y: auto;
        padding-bottom: 5px;
      }

      .up-down-icon {
        width: 10px;
        padding-right: 13px;
        float: right;
        padding-left: 0;

        .arrow-down {
          border: solid #7a7e92;
          border-width: 0 2.5px 2.5px 0;
          display: inline-block;
          padding: 3px;
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
        }

        .arrow-up {
          padding: 3px;
          margin-bottom: -3px;
          border: solid #7a7e92;
          border-width: 0 2.5px 2.5px 0;
          display: inline-block;
          transform: rotate(-135deg);
          -webkit-transform: rotate(-135deg);
        }
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}
