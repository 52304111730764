@import "../../styles/variables";

.providers-block {
  width: 100%;
  text-align: center;

  .providers-container {
    z-index: 20;
    position: fixed;
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center;
    height: 0;
    background-color: $themeColor4;
    border-bottom: 1px solid $themeColor1;

    .tab-button {
      line-height: 40px;
    }
  }
}