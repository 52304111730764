.tennis-instant-market {
  width: 75%;
  height: 100%;
  margin-right: 1%;
  position: relative;
  background: rgba(7, 17, 37, 0.7);

  .market-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 10px 10px 10px;
    height: calc(100% - 10vw);
    box-sizing: border-box;

    .market-types {
      display: flex;
      justify-content: space-between;
      padding: 0 4px;

      div {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }

      p {
        margin: 0;
        width: 24%;
        height: 2vw;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        cursor: pointer;
        font-size: 1vw;
        background: #ffffff1a;
        box-shadow: 0px 0px 5px 1px #ffffff5e;
      }

      .active {
        color: #00ffde;
      }
    }

    .market-content {
      display: flex;
      justify-content: space-between;
      height: calc(100% - 3vw);

      > div {
        margin-top: 10px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: space-between;
        height: 100%;
        overflow-y: auto;
        padding: 0 4px;

        &::-webkit-scrollbar-track {
          background: #151b2c00;
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #394a60;
          border-radius: 10px;
        }

        .selection {
          display: flex;
          justify-content: space-around;
          align-items: center;
          background-color: #ffffff1a;
          box-shadow: 0px 0px 5px 1px #ffffff5e;
          padding: 0 10px;
          margin: 5px 0;
          height: 10.2%;
          width: 49.3%;
          box-sizing: border-box;
          font-size: 1vw;
          cursor: pointer;

          > div {
            width: 100%;
            display: flex;
            flex-direction: column;          }

          .marketName {
            display: flex;
            align-items: center;
            color: #00ffde;
            margin: 0;
          }

          &.choose {
            background-color: #00ffde5c;
          }

          .score {
            font-size: 0.88vw;
          }

          .cashOut {
            width: 20px;
            height: 20px;
            margin-left: 10px;
          }

          img {
            width: 4%;
          }
        }

        .coefficient {
          color: #00ffde;
        }
      }
    }
  }
}
