@import "../../styles/variables";

.bet-slip-block {
  position: fixed;
  right: 0;
  width: 19.2%;
  height: 100%;
  float: left;
  margin-left: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: $themeColor0;

  .bet-slip-block-content {
    width: 100%;
    cursor: default;

    .bets-list-container {

      .bet-items-control-panel {
        display: flex;
        width: 100%;
        height: 35px;
        background-color: $themeColor3;
        justify-content: space-between;

        .custom-select {
          float: left;
          display: flex;
          width: 40%;
          height: 100%;
          border-radius: 0 !important;;
          background: $themeColor3;
          font-weight: bold;

          select {
            background: $themeColor3;
            font-size: 12px;
            font-weight: bold;
            height: 35px;
            cursor: pointer !important;
            border-radius: 0 !important;
            color: #c0c0cb;
          }

          &:after {
            line-height: 36px !important;
          }
        }

        .clear-bet-items {
          display: flex;
          justify-content: flex-end;
          white-space: pre;
          font-size: 12px;
          font-weight: bold;
          line-height: 35px;
          text-align: right;
          padding-right: 16px;
          margin-left: 9px;
          cursor: pointer;
          color: #c0c0cb;

          &:hover {
            color: #eaeaf1;
          }
        }
      }

      .bet-odds-block {
        margin-top: 5px;
        height: 28px;

        p {
          float: right;
          font-size: 12px;
          line-height: 4px;
          font-weight: bold;
          margin-right: 11px;

          b {
            color: $alpine;
          }
        }
      }
    }

    .login-hint-block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 13px;
      background-color: #393c49;
      color: #d4d5dd;

      .hint-container {
        width: 95%;
        line-height: 20px;
      }

      span {
        font-weight: bold;
        cursor: pointer;
        color: $alpine;

        &:hover {
          color: rgba($alpine, 0.8);
        }
      }
    }
  }

  .decimal-types-checkbox {

    .custom-select {
      margin-top: -3px;
      border-radius: 0 !important;;
      margin-bottom: -3px;

      select {
        height: 38px;
        cursor: pointer !important;
        border-radius: 0 !important;
        color: white;
        background-color: $themeColor0;
      }

      &:after {
        line-height: 38px;
        background-color: $themeColor0;
      }
    }
  }

  .bet-slip-menu {
    display: flex;
    width: 100%;
    background-color: $themeColor2;
    border-top: 2px solid $betSlipLine;
    cursor: pointer;

    .bet-slip-menu-tab {
      display: flex;
      width: 50%;
      height: 35px;
      line-height: 38px;
      text-align: left;
      padding-left: 10px;
      font-size: 11px;
      letter-spacing: 0.5px;
      white-space: pre;
      font-weight: bold;
      justify-content: space-between;

      .active-bets-count {
        display: flex;
        padding-right: 5px;
        padding-bottom: 1px;
        padding-left: 5px;
        height: 16px;
        margin-right: 9px;
        margin-top: 9px;
        background-color: $asset1;
        font-size: 10px;
        line-height: 16px;
        text-align: center;
        border-radius: 2px;
        justify-content: center;
      }
    }

    .active-tab {
      background-color: $themeColor4;
    }

    .calculator-icon {
      width: 16px;
      height: 16px;
      float: right;
      margin: 10px;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .bet-slip-container {
    margin-top: 1px;
    user-select: none;

    &.hide {
      display: none;
    }

    .quick-bet-block, .superbet-block, .offer-bet-block {
      display: flex;
      width: 100%;
      height: 32px;
      padding-top: 2px;
      background-color: $themeColor4;
      border-bottom: 1px solid $themeColor2;

      p {
        cursor: default;
        font-size: 13px;
        display: flex;
        padding-left: 7px;
        margin-top: 6px;
        font-weight: normal;
      }
    }

    .quick-bet-block p {
      white-space: pre;
    }

    .offer-bet-block {
      color: $alpine;
      cursor: default;

      p {
        white-space: pre;
      }

      .new-icon {
        height: 15px;
        color: white;
        font-size: 10px;
        line-height: 15px;
        font-weight: bold;
        margin-top: 5.5px;
        margin-left: 6px;
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 2px;
        border-radius: 2px;
        background-color: $alpine;
      }
    }

    .offer-bet-content {
      display: flex;
      width: 100%;
      background-image: linear-gradient(to right top, #1a5c7b, #225179, #314472, #403667, #4c2657);

      p {
        z-index: 1;
      }
    }

    .super-bet-content {
      display: flex;
      width: 100%;
      background-image: linear-gradient(to right top, #228e69, #007a74, #006574, #004f68, #1e3953);

      p {
        z-index: 1;
      }
    }

    .offer-bet-content, .super-bet-content {
      p {
        width: 140px;
        font-size: 11.5px;
        letter-spacing: 0.5px;
        text-align: left;
        padding-left: 10px;
        cursor: default;
      }
    }

    .bet-slip-icon {
      width: 80px;
      position: absolute;
      right: 15px;
      margin-top: 20px;
    }

    .quick-bet-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      background-color: $themeColor2;

      &.hide {
        display: none;
      }

      .quick-bet-info {
        width: 96%;
        line-height: normal;
        background-color: $themeColor0;
        margin-bottom: 4px;
        color: #aeb1b5;
        text-align: left;
        font-size: 11px;
        padding: 5px 8px 2px;
        cursor: default;
      }

      .stake-container {
        position: relative;
        margin: 4px 0 8px 6px;

        .stake-input {
          width: 95%;
          box-sizing: border-box;
          background: $themeColor3;
          border: none;
          height: 32px;
          float: left;
          box-shadow: 0 0 0 1px $themeColor5 inset;
          font-size: 12px;
          font-weight: bold;
          line-height: 30px;
          color: #c1c4cd;
          padding: 0 10px;
          outline: none;

          &.saved {
            color: #90929b;
          }
        }

        .save-stake, .edit-stake {
          width: 6.5vw;
          height: 32px;
          line-height: 29px;
          background: $asset2;
          font-weight: bold;
          font-size: 14px;
          position: absolute;
          right: 10px;
          cursor: pointer;

          &:hover {
            background-color: $asset3;
          }

          &:active {
            background-color: $asset2;
          }
        }
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        margin: 0;
      }
    }
  }

  .bet-hint {
    display: block;
    width: 96%;
    line-height: 24px;
    font-size: 12px;
    text-align: left;
    cursor: default;
    color: #aeb1b5;
    padding-left: 10px;
    padding-bottom: 3px;
    background-color: #212531;
  }

  .place-bet-process {
    float: left;
    width: 100%;
    height: 28px;
    margin-bottom: 5px;
    border-radius: 2px;
    background-color: #2E323E;
    position: relative;

    .loader {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 4px;
      left: 43%;
      border-radius: 50%;
      background: transparent;
      border-top: 3px solid $asset1;
      border-right: 3px solid transparent;
      animation: 0.5s spin linear infinite;
    }
  }

  .place-bet-results {
    float: left;
    width: 100%;
    height: auto;
    border-radius: 2px;
    background-color: #181b25;
    position: relative;
    font-size: 12px;
    line-height: 25px;
    color: white;
    margin-bottom: 5px;

    &.success {
      background-color: #227e37;
    }

    &.fail {
      background-color: #b32020;
    }

    &.progress {
      background-color: #272b3b;
    }
  }
}