.play-now {
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 5px;
  position: relative;
  background: rgba(0, 0, 0, 0.55);
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 85%;
    padding: 12px 0px;
    box-sizing: border-box;

    .vs-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 15%;

      .vs {
        margin-right: 3%;
        height: fit-content;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 15%;
    position: absolute;
    bottom: 0;
    border-top: 2px solid #ff0d98;

    .random-button,
    .play-button {
      margin-left: 25px;
      margin-right: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
