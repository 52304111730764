.tennis-instant-bet-slip {
  * {
    box-sizing: border-box;
  }

  display: flex;
  flex-direction: column;
  width: 25%;
  height: 100%;
  border-left: 2px solid #e30081;
  user-select: none;
  background: rgba(7, 17, 37, 0.7);
  box-sizing: border-box;

  .error-message {
    background-color: #ff00009e;
    position: absolute;
    top: 0x;
    right: 0;
    height: 3vw;
    z-index: 2;
    width: 0;
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
    border-radius: 0 0 0 5px;

    .close-error-msg {
      position: absolute;
      right: 5px;
      top: 5px;
      cursor: pointer;
    }

    &.active {
      transition: width 1s ease-in-out;
      width: 90%;
      display: flex;
      align-items: center;
    }

    img {
      margin-right: 5px;
      margin-left: 5px;
    }

    .loading {
      animation: animate-width 10s linear;
      height: 3px;
      background-color: #ffff;
      width: 100%;
      position: absolute;
      bottom: 0;
    }

    @keyframes animate-width {
      100% {
        width: 0;
      }
    }
  }

  .bet-items-block-instant {
    position: relative;
    width: 100%;
    height: 100%;

    .error-message-common-bets {
      width: 100%;
      position: absolute;
      top: 0%;
      background-color: red;
    }

    &-content {
      height: 100%;

      &-board {
        height: calc(80% - 30px);
        position: relative;
        margin: 10px 10px 0 10px;

        &::-webkit-scrollbar-thumb {
          background: #394a60;
          border-radius: 10px;
        }

        &::-webkit-scrollbar-track {
          background: #151b2c00;
          border-radius: 10px;
        }
      }
    }
  }

  .tennis-instant-bet-slip-items {
    overflow-y: auto;
    height: calc(100% - 2.5vw);
    border: 1px solid #00ffde6b;
  }

  .tennis-instant-bet-slip-items-footer {
    position: absolute;
    bottom: 0;
    min-height: 2.5vw;
    width: 100%;
    background-color: #0e4c53;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;

    span {
      font-size: 12px;
      margin-top: 5px;
    }

    .posible-winning-amount {
      background-color: #0f12125c;
      border: 1px solid #00ffde;
      border-radius: 10px;
      text-align: center;
      width: 90%;
      margin: 5px 0 10px 0;
    }
  }

  .bet-slip-btns {
    margin-top: 10px;
    position: absolute;
    bottom: 0;
  }

  .clear-all {
    position: absolute;
    bottom: -21px;
    right: -3px;
    font-size: 10px;
    color: #ffffff78;
    cursor: pointer;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: calc(100% - 20px);
    height: 20%;
    position: absolute;
    margin: 10px;
    bottom: 0;

    .button {
      width: 100%;
      height: 2.3vw;
      line-height: 2vw;
      font-size: 1.2vw;

      &.place-bet {
        color: #55e7d7;

        &.next-point {
          &::after {
            content: "NEXT POINT";
            animation: identifier 2s linear infinite;
          }

          @keyframes identifier {
            0% {
              content: "NEXT POINT";
            }

            100% {
              content: "PLACE BET";
              font-size: 1vw;
            }
          }
        }
      }

      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }
}
