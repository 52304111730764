@import "../../styles/variables";

.empty-data {
  opacity: 0;
  -webkit-animation: 2s ease 2s normal forwards 1 fadein;
  animation: 2s ease 2s normal forwards 1 fadein;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 29vw;
  top: 50%;
  left: 50%;

  img {
    width: inherit;
  }

  h3 {
    font-family: Roboto, serif !important;
    text-align: justify;
    color: $mainBackgroundColor;
    position: absolute;
    font-weight: bold;
    font-size: 15vw !important;
    text-shadow: 0px -1px 4px #fffcfc87;
    z-index: -1;
    overflow: hidden;
    margin-top: 29%;
    margin-left: -10%;
  }

  h3:before {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: bottom;
    transform: rotateX(180deg);
    line-height: 1;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), $mainBackgroundColor);
    opacity: 0.3;
    -webkit-background-clip: text;
  }

  p {
    font-family: Roboto, serif !important;
    position: absolute;
    text-align: justify;
    left: 5%;
  }

  :nth-child(3) {
    color: red;
    bottom: 20%;
    font-size: 1vw;
  }

  :nth-child(4) {
    opacity: 0.4;
    bottom: 5%;
    font-weight: 100 !important;
    font-size: 0.8vw;
  }

  .maintenance {
    width: 48px;
    margin-right: 10px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
}