@import "../../../styles/variables";

.bet-switcher {
  display: flex;
  margin-top: 1px;
  margin-left: 1px;
  white-space: pre;

  label {
    margin-top: 4px;
    cursor: pointer;
    text-indent: -9999px;
    width: 30px;
    height: 16.6px;
    background: grey;
    display: block;
    border-radius: 50px;
    position: relative;
  }

  label:after {
    content: '';
    position: absolute;
    top: 2.05px;
    left: 3px;
    width: 12px;
    height: 12px;
    background: #fff;
    border-radius: 100px;
    transition: 0.2s;
  }

  input[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  input:checked + label {
    background: $asset3;
  }

  input:checked + label:after {
    left: calc(100% - 2.5px);
    transform: translateX(-100%);
  }

  label:active:after {
    width: 28px;
  }
}