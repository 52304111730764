@import "../../../styles/variables";

.basic-market {
  width: 100%;

  .basic-market-container {
    width: 100%;
    float: left;
    display: flex;
    margin-top: 5px;
    margin-bottom: 20px;
    cursor: default;
    padding-bottom: 75px;
  }

  .basic-market-list {
    width: 100%;
    float: left;
    display: flex;
    margin-bottom: 15px;
    flex-direction: column;
  }

  .basic-market-list-item {
    float: left;
    padding: 9px;
    font-size: 12px;
    border-left: 1px solid #0d0d13;
    border-bottom: 1px solid #0d0d13;
  }

  .match-running-info {
    width: 100%;
    float: left;
    margin-top: 1.5vw !important;
    color: #898c99;
    font-size: 14px;
  }

  .list-title {
    max-height: 16px;
    white-space: pre;
    font-size: 13px;
    text-align: center;
    background-color: $themeColor2;

    &.player-name {
      text-align: left;
    }
  }

  .list-info-item {
    background-color: $themeColor4;
    font-weight: bold;
    
    &.active {
      color: $listInfoActiveItemColor;
    }
  }

  .price {
    background-color: $themeColor4;
    color: $themeColorOdd !important;

    &:hover {
      background-color: $themeColor5;
      color: $themeColorOddHover !important;
      cursor: pointer;
    }

    &.active {
      color: $themeColorOddActive !important;
    }
  }

  .active, .active:hover {
    background-color: $selectionHighlight;
  }

  .player-item {
    min-width: 24vw;
    padding-right: 20px;
    text-align: left;

    .marble-icon {
      float: left;
      width: 15px;
      height: 15px;
      border-radius: 30px;
    }

    embed {
      float: left;
      margin: 0;
      padding: 0;
    }

    p {
      float: left;
      padding-left: 10px;
    }
  }
}

