@import "../../../styles/variables";

$additionInfoHeight: 32px;
$stadiumBlockHeight: 34px;
$upcomingGamesHeight: 55px;
$borderRadius: 2px;

.upcoming-games {
  width: 100%;
  float: left;
  margin-bottom: 5px;
  border-radius: $borderRadius;
}

.addition-info {
  height: $additionInfoHeight;
  border-bottom: 2px solid $asset2;
  border-top-right-radius: $borderRadius;
  border-top-left-radius: $borderRadius;

  background: #121926;
  background: -moz-linear-gradient(top,  #121926 0%, #1e2430 100%);
  background: -webkit-linear-gradient(top,  #121926 0%,#1e2430 100%);
  background: linear-gradient(to bottom,  #121926 0%,#1e2430 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#121926', endColorstr='#1e2430', GradientType=0);

  img {
    float: left;
    width: 20px;
    height: 12px;
    padding-top: 9px;
    padding-left: 4px;
    padding-right: 2px;
  }
}

.addition-info p {
  float: left;
  font-size: 12px;
  color: #BBBBBE;
  line-height: $additionInfoHeight;
  border-top-right-radius: 2px;
}

.upcoming-games-content {
  width: 100%;
  height: $upcomingGamesHeight;
  background-color: $themeColor4;
  border-bottom: 2px solid $asset2;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.upcoming-games-content p {
  float: left;
  font-size: 13px;
  font-weight: bold;
  margin-left: 12px;
  line-height: $upcomingGamesHeight;
}

.upcoming-games-times {
  margin-top: 11px;
  margin-right: 12px;
  display: flex;
  float: right;
}

.game-start-time {
  margin: 4px 3px 3px 5px;
  padding: 5px 10px 6px 10px;
  font-size: 12px;
  border-radius: 2px;
  cursor: pointer;
  font-weight: bold;
  background-color: $themeColor2;
  color: #d0d0d0;

  &:hover, &.active {
    background-color: $asset1;
    color: white;
  }
}

.stadium-name {
  height: $stadiumBlockHeight;
  background-color: $themeColor2;
  border-top: 1px solid #181c23;
  border-bottom-right-radius: $borderRadius;
  border-bottom-left-radius: $borderRadius;
}

.stadium-name p {
  float: left;
  margin-left: 12px;
  font-size: 12px;
  line-height: 34px;
}