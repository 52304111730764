@import "../../../../styles/variables";

.fights-block {
  width: 100%;
  height: 100%;
  display: inline-block;
  background-color: $themeColor4;
  border-radius: 3px;
  margin-top: 3px;
  margin-bottom: 5px;

  p {
    font-size: 13px;
    font-weight: bold;
    text-align: left;
    float: left;
    margin-top: 7px;
    margin-left: 12px;
    cursor: default;
    padding-bottom: 5px;
    line-height: 35px;
    padding-top: 5px;
  }

  .weight-list {
    height: 40px;
    border-bottom: 2px solid $asset2;

    .weight-buttons {
      display: flex;
      margin: 15px;

      .weight-button {
        height: 25px;
        font-size: 12px;
        line-height: 25px;
        background-color: #cbcbd5;
        cursor: pointer;
        text-align: center;
        margin-right: 8px;
        border-radius: 2px;
        color: #1c1c2a;
        padding-left: 7px;
        padding-right: 7px;
        font-weight: bolder;

        &:hover {
          background-color: white;
        }

        &.active {
          background-color: $asset1;
          color: white;
          cursor: default;
        }
      }
    }
  }

  .fights-container {
    display: flex;
    width: 100%;
    height: 380px;
    align-items: center;
    background-color: $themeColor2;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    .fights-column {
      display: flex;
      flex: 7;
      height: 90%;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      margin-right: 1px;

      .column-title {
        width: 100%;
        height: 10px;
        font-size: 13px;
        line-height: 11px;
        text-align: center;
        position: absolute;
        top: -22px;
        color: $alpine;
      }

      .item {
        width: 85%;
        height: 54px;
        border-radius: 3px;
        background-color: $themeColor4;
        position: relative;
        cursor: pointer;
        border-bottom: 1px solid $themeColor3;

        &:hover {
          background-color: $themeColor5;

          .player .player-score {
            //opacity: 0.5;
          }
        }

        &.active {
          cursor: default;
          user-select: none;
          background-color: $themeColor1;
          border-bottom: 1px solid $themeColor1;
        }

        &.passive {
          cursor: default;
          user-select: none;
          background: $themeColor3;
        }

        .player {
          display: flex;
          width: 100%;
          height: 27px;
          line-height: 24px;
          font-size: 11px;
          text-align: center;
          white-space: pre;
          color: #eaeaea;
          position: relative;
          border-radius: 4px;

          .player-name {
            display: flex;
            align-items: center;
            max-width: 92%;
            position: absolute;
            overflow: hidden;
            line-height: 25px;
            left: 6px;

            img {
              width: 18px;
              height: 18px;
              margin-right: 5px;
            }
          }

          .player-score {
            width: 20px;
            height: 26px;
            background-color: $themeColor1;
            border-bottom: 1px solid $themeColor1;
            border-left: 2px solid $themeColor3;
            position: absolute;
            border-radius: 2px;
            right: 0;

            &.winner {
              background-color: $asset2;
              border-bottom: none;
            }

            &.defeated {
              background-color: #CA3E47;
              border-bottom: none;
            }

            &.draw {
              background-color: #576171;
            }
          }

          &.top-player {
            border-bottom: 2px solid $themeColor4;
          }
        }

        .line-h {
          width: 95%;
          height: 2px;
          background-color: $themeColor3;
          position: absolute;
          top: 50%;
          left: 15%;

          &.i-2, &.i-1 {
            width: 118%;
            left: -8.5%;
          }

          &.r {
            left: -10%;
          }
        }

        .line-v {
          width: 2px;
          height: 258px;
          background-color: $themeColor3;
          position: absolute;
          top: 50%;
          right: -10%;

          &.i-2 {
            height: 173px;
          }

          &.r {
            right: 110%;
          }
        }
      }
    }
  }
}