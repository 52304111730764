:root {
  --main-bg: #030C13;
  --vs-col0: #161C28;
  --vs-col1: #16161d;
  --vs-col2: #1e222d;
  --vs-col3: #292C38;
  --vs-col4: #2f323e;
  --vs-col5: #404556;
  --vs-col6: #5e6474;
  --vs-odd: #d4961b;
  --vs-odd_hover: #e5a630;
  --vs-odd_active: #cf911e;
  --vs-selection: #d4961b;
  --vs-selection_hover: #e5a630;
  --vs-selection_active: #cf911e;
  --vs-listInfoItemColor: #aa8136;
  --vs-selectionHighlight: #d8298f;
  --vs-promotionLine: #d8298f;
  --vs-gameHighlight: #d8298f;
  --vs-betSlipLine: #d8298f;
  --vs-marketGroupUnderline: #d8298f;
  --vs-maxBet: #de9d1b;
  --vs-maxBetHover: #e5a630;
  --vs-maxBetActive: #d4961a;
  --vs-myBetsLine: #d8298f;
  --vs-resultsLine: #d8298f;

  --vs-asset1: #d8298f;
  --vs-asset2: #008D47;
  --vs-asset3: #1a984c;
  --vs-asset4: #aa8136;
}

// Skin colors
$mainBackgroundColor: var(--main-bg);
$themeColor0: var(--vs-col0);
$themeColor1: var(--vs-col1);
$themeColor2: var(--vs-col2);
$themeColor3: var(--vs-col3);
$themeColor4: var(--vs-col4);
$themeColor5: var(--vs-col5);
$themeColor6: var(--vs-col6);
$themeColorOdd: var(--vs-odd);
$themeColorOddHover: var(--vs-odd_hover);
$themeColorOddActive: var(--vs-odd_active);
$themeColorSelection: var(--vs-selection);
$themeColorSelectionHover: var(--vs-selection_hover);
$themeColorSelectionActive: var(--vs-selection_active);
$listInfoItemColor: var(--vs-listInfoItemColor);
$listInfoActiveItemColor: var(--vs-listInfoActiveItemColor);
$selectionHighlight: var(--vs-selectionHighlight);
$promotionLine: var(--vs-promotionLine);
$gameHighlight: var(--vs-gameHighlight);
$betSlipLine: var(--vs-betSlipLine);
$marketGroupUnderline: var(--vs-marketGroupUnderline);
$maxBet: var(--vs-maxBet);
$maxBetHover: var(--vs-maxBetHover);
$maxBetActive: var(--vs-maxBetActive);
$myBetsLine: var(--vs-myBetsLine);
$resultsLine: var(--vs-resultsLine);

// Accent colors
$asset1: var(--vs-asset1);
$asset2: var(--vs-asset2);
$asset3: var(--vs-asset3);
$alpine: var(--vs-asset4);

// Sizes
$providersBlockHeight: 36px;
$sportsBlockHeight: 71px;