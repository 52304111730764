@import "../../../styles/variables";

$color: #b6b6bb;

.league-menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 7px;

  .league-menu-tab {
    display: flex;
    margin-bottom: 3px;
    text-align: left;
    font-size: 12px;
    flex-direction: column;
    justify-content: space-between;

    .arrow {
      border: solid #68686A;
      border-width: 0 2px 2px 0;
      display: inline-block;
      width: 7px;
      height: 7px;
      margin-right: 12px;
    }

    .up-arrow {
      margin-top: 11px;
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
    }

    .down-arrow {
      margin-top: 7px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }

    .league-menu-tab-title {
      display: flex;
      flex-flow: wrap;
      flex-direction: row;
      box-sizing: border-box;
      justify-content: space-between;
      cursor: pointer;
      height: 30px;
      color: $color;
      border-radius: 2px;
      background-color: #212431;

      p {
        padding-top: 6px;
        margin-left: 8px;
      }
    }

    .league-menu-tab-title:hover {
      background-color: #2b2f40;
    }

    .league-market-content {
      width: 100%;
      display: flex;
      margin-top: 2px;
      text-align: center;
      margin-bottom: 5px;

      .large-market {
        margin-top: 3px;
      }
    }

    .league-market-content-hidden {
      display: none;
    }

    .large-market {
      margin-top: 10px;
    }
  }
}