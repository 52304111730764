.end-match {
    width: 100%;
    height: calc(100% - 10vw);
    overflow-y: auto;
    box-sizing: border-box;
    position: relative;
    border-top: 3px solid #e30081;
    width: calc(100% - 20px);
    margin: auto;
    border-radius: 3px;

    .ball {
        display: none;
    }

    .won-icon {
        color: #1de41d;
    }

    &::-webkit-scrollbar-track {
        background: #151b2c00;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background: #394a60;
        border-radius: 10px;
    }

    .my-bets-instant-item {
        background-color: #74adca38;
        border-color: #00ffde73;
    }
}
