@import "../../../styles/variables";

.bet-history-container {
  display: flex;
  cursor: default;
  background-color: $themeColor4;
  flex-direction: column;
  align-items: center;

  .view-history-hint {
    width: 95%;
    font-size: 13px;
    line-height: 30px;
    padding-top: 10px;
    padding-bottom: 10px;

    b {
      color: $alpine;
      cursor: pointer;

      &:hover {
        color: #e9b95f;
      }
    }
  }
}