.players-slider {
  width: 100%;
  height: 41%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.15s ease-in-out;

  .player-block-inst {
    position: relative;
    filter: saturate(0.2);

    .players-icon-bg {
      width: 75%;
      margin-top: 6%;
    }

    &.active {
      filter: none;
    }

    &.isEcual {
      filter: saturate(0.2);
    }
  }

  .player-icon {
    width: 75%;
    margin-top: 3%;
    right: 35%;
  }

  .player-star {
    position: absolute !important;
    bottom: -10%;
    transform: translate(-50%, 0);
    left: 75%;
    width: 100%;
    display: flex;
    align-items: center;

    span {
      font-size: 1.2vw !important;
    }

    [data-index="2"] {
      font-size: 1.5vw !important;
    }
  }

  .slick-slider {
    transition: all 0.15s ease-in-out;
    width: 90%;

    .slick-list {
      > div {
        margin-bottom: 2vw;
      }
    }

    .slick-arrow {
      width: 40px;
      height: 30px;
      z-index: 100;

      $col: #c1c8d0;
      &:hover {
        &:before {
          color: #172135;
          text-shadow: 1px 0 0 $col, -1px 0 0 $col, 0 1px 0 $col, 0 -1px 0 $col, 1px 1px $col, -1px -1px 0 $col,
            1px -1px 0 $col, -1px 1px 0 $col;
        }
      }
    }

    .slick-next {
      padding-left: 10px;

      &:before {
        content: url("./slickIcons/next.png");
        font-weight: bold;
        font-size: 45px;
        line-height: 0.5;
        opacity: 0.75;
        color: #172135;
        text-shadow: 1px 0 0 #9baab8, -1px 0 0 #9baab8, 0 1px 0 #9baab8, 0 -1px 0 #9baab8, 1px 1px #9baab8,
          -1px -1px 0 #9baab8, 1px -1px 0 #9baab8, -1px 1px 0 #9baab8;
        // background-image: url("./slickIcons/next.png");

      }s
      &:hover::before {
        content: url("./slickIcons/next-hover.png");
      }
    }

    .slick-prev {
      display: block;
      // transform: rotate(180deg);
      &:before {
        content: "<"; //url("./slickIcons/next.png");
        font-weight: bold;
        font-size: 45px;
        line-height: 0.5;
        opacity: 0.75;
        color: #172135;
        text-shadow: 1px 0 0 #b0bbc6, -1px 0 0 #b0bbc6, 0 1px 0 #b0bbc6, 0 -1px 0 #b0bbc6, 1px 1px #b0bbc6,
          -1px -1px 0 #b0bbc6, 1px -1px 0 #b0bbc6, -1px 1px 0 #b0bbc6;
        // background-image: url("./slickIcons/next.png");

      }

      &:hover::before {
        content: "<";//url("./slickIcons/next-hover.png");
      }
    }

    .slick-slide {
      transform: scale(0.75);
      transition: all 0.15s ease-in-out;
    }

    .slick-current {
      transition: all 0.15s ease-in-out;
      transform: scale(1.1) !important;

      div {
        .player-block-inst {
          .name {
            background-color: #171d2f;
          }
        }
      }
    }
  }
}

.players-slider,
.tennis-players-block {
  .name {
    text-align: center;
    position: absolute;
    line-height: 1.9vw;
    font-size: 0.7vw;
    bottom: 5%;
    color: #101421;
    font-weight: bold;
    left: 43%;
    white-space: nowrap;
    transform: translate(-50%, 12px);
    -webkit-background-clip: text;
    text-shadow: 1px 0 0 #919fac, -1px 0 0 #919fac, 0 1px 0 #919fac, 0 -1px 0 #919fac, 1px 1px #919fac,
      -1px -1px 0 #919fac, 1px -1px 0 #919fac, -1px 1px 0 #919fac;
  }
}
