@import "../../../styles/variables";
$borderColor: $themeColor5;

.max-bet-container {
  width: 96%;
  margin: auto;

  .stake-block {
    width: 100%;
    display: flex;
    margin-top: 2px;
    margin-bottom: 2px;
    justify-content: space-between;
    font-size: 12px;
    font-weight: bold;

    input {
      width: 35%;
      box-sizing: border-box;
      background: #4B505E;
      border: none;
      height: 28px;
      float: left;
      font-size: 12px;
      line-height: 32px;
      color: #e5e5e5;
      padding: 0 4px;
      outline: none;
      text-align: center;
      font-weight: bold;

      &:focus {
        background: #414652;
      }
    }

    .stake-button {
      width: 9%;
      background: #7B7F8A;
      border-radius: 2px;
      text-align: center;
      line-height: 28px;
      height: 28px;
      cursor: pointer;
      color: white;
      padding: 0 2px;
      font-size: 10px;

      &:hover {
        opacity: 0.9;
      }

      &:active {
        opacity: 0.8;
      }

      &.minus {
        width: 6%;
        font-size: 12px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        margin-right: -1.7%;
      }

      &.plus {
        width: 6%;
        font-size: 12px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-left: -1.8%;
      }

      &.max-button, &.min-button {
        width: 12%;
      }
    }
  }

  .max-bet-input {
    width: 70%;
    box-sizing: border-box;
    background: $themeColor3;
    height: 30px;
    float: left;
    border: 1px solid $borderColor;
    line-height: 30px;
    color: #c1c4cd;
    padding: 0 10px;
    border-radius: 2px;
    outline: none;
    font-weight: bold;
    font-size: 12px;
  }

  .max-bet-button {
    float: left;
    width: 30%;
    height: 30px;
    line-height: 30px;
    font-size: 11px;
    font-weight: bold;
    background-color: $maxBet;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    cursor: pointer;
    color: white;

    &:hover {
      background-color: $maxBetHover;
    }

    &:active {
      background-color: $maxBetActive;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0;
  }

  .bet-options {
    display: flex;
    width: 100%;
    float: left;
    flex-wrap: wrap;

    .bet-option {
      flex: 1 0 20%;
      height: 30px;
      font-size: 12px;
      line-height: 30px;
      margin: 8px 8px 0 0;
      font-weight: bold;
      background-color: #7B7F8A;
      border-radius: 2px;
      cursor: pointer;

      &:hover {
        opacity: 0.9;
      }

      &:active {
        background-color: #666973;
      }
    }

    .bet-option-input {
      flex: 1 0 20%;
      width: inherit;
      height: 28px;
      padding: 0;
      line-height: 28px;
      font-size: 12px;
      margin: 8px 8px 0 0;
      font-weight: bold;
      text-align: center;
      color: #c1c4cd;
      outline: none;
      background-color: #2F323E;
      border: 0.1px solid $borderColor;
      border-radius: 2px;
      &:focus {
        border: 0.1px solid #fff;
      }
    }
  }

  .edit-options {
    float: left;
    width: 11% !important;
    height: 28px;
    line-height: 25px;
    margin-top: 8px;
    border: 1px solid #393d4a;
    border-radius: 2px;
    cursor: pointer;

    user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;

    &:hover {
      border: 1px solid $borderColor;
    }
  }

  .place-bet-container {
    width: 100%;

    .place-bet {
      width: 100%;
      display: flex;
      height: 30px;
      line-height: 30px;
      float: left;
      margin-top: 5px;
      margin-bottom: 5px;
      color: #686c79;
      background-color: $themeColor4;
      justify-content: center;
      font-size: 13px;
      font-weight: bold;
      border-radius: 2px;
      cursor: default;

      &.enabled {
        cursor: pointer;
        background-color: $asset2;
        border: 1px solid $asset2;
        color: white;

        &:hover {
          background-color: $asset3;
        }

        &:active {
          background-color: $asset2;
        }
      }
    }

    .bet-warning {
      display: flex;
      float: left;
      width: 100%;
      padding-top: 5px;
      padding-bottom: 6px;
      font-size: 13px;
      font-weight: bold;
      margin-bottom: 5px;
      border-radius: 2px;
      background-color: #9c9cac;
      color: #222226;
      cursor: default;
      justify-content: center;
    }
  }

  .potential-winning-container {
    width: 100%;
    float: left;
    color: white;
    display: flex;
    margin-top: 8px;
    border-radius: 2px;
    flex-direction: column;
    background-color: $themeColor5;
    font-weight: bold;
    cursor: default;

    .potential-win-title {
      font-size: 12px;
      margin-bottom: 5px;
    }

    .potential-win-value {
      margin-top: 0;
      margin-bottom: 14px;
    }

    .tax-info {
      width: 100%;
      font-size: 12px;
      margin-bottom: 5px;
      padding-top: 5px;
      border-top: 1.5px solid #6e6e7a;

      .win-info {
        display: flex;
        justify-content: space-between;
        padding-left: 15px;
        padding-right: 15px;
        height: 27px;
        line-height: 0;
        font-weight: normal;
      }
    }
  }

  .system-calculator-block {
    display: flex;
    width: 98%;
    float: right;
    margin-right: 1px;
    margin-bottom: 0;
    font-weight: bold;
    font-size: 14px;
    color: #c1c4cd;
    margin-top: 8px;

    span {
      padding-right: 5px;
    }

    .system-coef {
      display: flex;
      line-height: 30px;
      padding-right: 8px;
    }
  }
}
