.slider {
  width: 100%;

  .slider-container {
    display: block;
    position: relative;
    width: 100vw;

    .slider-buttons {
      height: 40px;
      display: block;
      position: absolute;
      top: 30%;
      right: 4%;

      .slider-button {
        float: left;
        width: 30px;
        height: 30px;
        cursor: pointer;
        text-align: center;
        border-radius: 2px;
        background-color: rgba(26, 40, 76, 0.5);

        &.next {
          margin-left: 5px;
        }

        &:hover {
          background-color: rgba(42, 61, 103, 0.4);
        }

        .arrow {
          border: solid #b1afaf;
          border-width: 0 2.5px 2.5px 0;
          border-radius: 1px;
          display: inline-block;
          padding: 3px;
          margin: 10px 5px 5px;

          &.right {
            margin-right: 8px;
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
          }

          &.left {
            margin-left: 8px;
            transform: rotate(135deg);
            -webkit-transform: rotate(135deg);
          }
        }
      }
    }

    .CrossfadeImage {
      animation: fadeIn 0.3s ease-in;

      img {
        cursor: pointer;
      }
    }

    .slide {
      top: 0;
      background: none;

      img {
        border-radius: 3px;
      }

    }
  }
}