@import "../../styles/variables";

$tabWidth: 135px;
$backgroundColor: $themeColor0;
$tabBorderRadius: 4px;

.sports-block {
  width: 80%;
  height: $sportsBlockHeight;
  float: left;
  margin-left: 5px;
  background-color: $backgroundColor;
  border-radius: 3px;
  user-select: none;

  p {
    margin: 0;
  }

  embed {
    max-width: 20px;
    height: 15px;
    margin-top: 6px;
  }

  .sports-tabs {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  }

  .sports-tab-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: $sportsBlockHeight;
    font-size: 11.5px;
    position: relative;
    float: left;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    font-weight: bold;
    color: #e8eaee;
    background-color: $backgroundColor;
    -webkit-transition: background-color 0.3s ease-out;
    -moz-transition: background-color 0.3s ease-out;
    -o-transition: background-color 0.3s ease-out;
    transition: background-color 0.3s ease-out;

    .sport-name {
      width: 97%;
      font-size: 10.5px;
      white-space: break-spaces;
      position: relative;
    }

    .sport-icon {
      height: 45%;

      img {
        max-height: 26px;
        margin-top: 2px;
        pointer-events: none;
      }
    }
  }

  .is-first-tab {
    border-bottom-left-radius: $tabBorderRadius;
  }

  .is-last-tab {
    border-bottom-right-radius: $tabBorderRadius;
  }

  .sports-tab-button:hover {
    color: white;
    background-color: $asset1;
    box-shadow: 0 0 2px 2px inset rgba(0, 0, 0, 0.1);
  }

  .sports-tab-active {
    color: white;
    background-color: $asset1;
    box-shadow: 0 0 2px 2px inset rgba(0, 0, 0, 0.25);

    .sport-timeout {
      color: white;
    }
  }

  .sport-timeout {
    font-size: 10px;
    color: $alpine;
    position: absolute;
    position: absolute;
    top: 7px;
    left: 9px;

    &.live {
      width: fit-content;
      color: #61EF61;

      &:before {
        content: " ";
        width: 6px;
        height: 6px;
        margin-right: 6px;
        margin-top: 4px;
        float: left;
        border-radius: 50%;
        background: #61EF61;
        box-shadow: 0 0 0 #61EF61;
        animation: pulse 2s infinite;
      }
    }
  }
}