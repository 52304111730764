.bet-history-instant {
    height: calc(100% - 2vw - 1.5vw - 20px);
    overflow-y: auto;
    padding: 5px;
    margin-top: 10px;
    box-sizing: border-box;

    &::-webkit-scrollbar-track {
        background: #151b2c00;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background: #394a60;
        border-radius: 10px;
    }

    &-header {
        color: #00ffde;
        padding: 0.2vw;
        box-sizing: border-box;
        position: sticky;
        top: 0;
        box-shadow: 0px 0px 3px 3px #00ffde;
        z-index: 1;

        &-items {
            color: #00ffde;
            text-shadow: 1px 0px 0 #ffff00;
            background-color: #0e4c53;

            > div {
                &:nth-child(2) {
                    width: 35%;
                }

                &:nth-child(6) {
                    width: 12%;
                }
            }

            > div {
                position: relative;
            }
        }
    }

    &-header > div,
    .row {
        display: flex;
        justify-content: space-between;
    }

    &-content > div {
        box-shadow: 0px 0px 3px 3px #ffffff99;
        box-sizing: border-box;
    }

    &-header > div {
        box-shadow: 0px 0px 3px 3px #00ffde80;
        box-sizing: border-box;
    }

    &-content {
        > div {
            margin-top: 1%;
            padding: 3px;
        }
    }

    &-header > div > div,
    .row .column {
        display: flex;
        flex-direction: column-reverse;
        width: 16%;
        text-align: center;

        &:last-child {
            width: 10%;

            img {
                width: 50%;
            }

            img:hover {
                width: 40%;
                cursor: pointer;
            }
        }

        &:nth-child(3) {
            width: 23%;
        }
    }

    .row {
        height: 2.5vw;
        background-color: #ffffff00;
        box-shadow: 0px 0px 1px 2px #ffffff99;

        .column {
            display: flex;
            align-items: center;
            justify-content: center;
            height: inherit;
            position: relative;
            box-sizing: border-box;

            .winner-name,
            .bet-id span {
                color: #ffff00;
                font-weight: bold;
            }

            .stake-amount,
            .win-amount {
                color: #00ffde;
                font-weight: bold;
            }

            &:first-child {
                flex-direction: column;
            }

            p {
                margin: 0;
                text-align: left;
            }

            > div {
                display: flex;
                flex-direction: column;
                font-size: 0.7vw;

                &.details {
                    > div {
                        width: 100%;
                        flex-direction: row;
                        justify-content: space-between;
                    }
                    .bet-id {
                        margin-top: 0.3vw;
                    }

                    .hour {
                        margin-left: 5px;
                    }
                }
            }

            .status {
                img {
                    width: 70%;
                }
                &:hover {
                    .status-text {
                        display: block;
                        background-color: #00000061;
                    }
                }

                &-text {
                    position: absolute;
                    top: -3px;
                    left: 50%;
                    transform: translate(-50%, 0);
                    display: none;
                }
            }

            &.results {
                width: 35% !important;
            }

            &.status {
                width: 12%;
            }
        }
    }

    .row .column,
    &-header {
        .border {
            width: 1px;
            background: #00ffde61;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            box-shadow: 0 0 2px 1px #ffffff;
        }
    }
}

.filter-bet-history-instant {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    .game-name,
    label,
    .date .react-datepicker-wrapper input {
        color: #00ffde;
        text-shadow: 1px 0px 0 #ffff00;
        letter-spacing: 1px;
        font-size: 0.6vw;
    }

    .game-name {
        width: 20%;
        text-align: center;
        white-space: nowrap;
    }

    .game-name,
    .date .react-datepicker-wrapper input {
        border: 1px solid #00ffde;
        background-color: #00ffde26;
        padding: 0.5vw;
        box-sizing: border-box;
        border-radius: 30px;
        height: 1.5vw;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .date {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 49.7%;

        .react-datepicker-popper {
            z-index: 3;
        }

        .calendar {
            background-color: black;
        }

        > div {
            display: flex;
            align-items: flex-end;
            width: 40%;

            &:nth-child(3) {
                width: 12%;
            }

            img {
                width: 100%;
                cursor: pointer;
            }
        }

        label {
            margin: 0 5px;
        }

        input {
            cursor: pointer;
            text-align: center;
            outline: none;
        }
    }
}
