@import "../../../styles/variables";

.large-market {
  width: 100%;

  .large-market-container {
    width: 100%;
    float: left;
    display: flex;
    margin-top: 4px;
    cursor: default;
    flex-direction: column;
    padding-bottom: 75px;

    .match-running-info {
      margin-bottom: 20px;
    }
  }

  .large-market-element {
    display: flex;
    float: left;
    width: 100%;
    margin-top: 1px;
    margin-right: 3px;
    flex-direction: column;

    &.disable {
      .large-market-element-item-prices  {
        opacity: 0.3;
        pointer-events: none;
      }
    }
    .closed {
      display: none;
    }
  }

  .list-title {
    max-height: none !important;
    white-space: pre;
    font-size: 13px;
    background-color: $themeColor2;
  }

  .large-market-element-item {
    display: flex;
    flex-flow: wrap;
    flex-direction: row;
    box-sizing: border-box;
    justify-content: space-between;
    float: left;
    height: 28px;
    padding-left: 10px;
    line-height: 28px;
    font-size: 13px;
    border-radius: 2px 2px 0 0;
    contain: strict;
    background: $themeColor2;
    cursor: pointer;
  }

  .market-element-options {
    display: flex;
  }

  .star-bookmark {
    font-size: 21px;
    line-height: 26px;
    padding-right: 8px;
    cursor: pointer;
    color: #68686A;
  }

  .star-bookmark:hover, .bookmarked {
    color: #b18636;
  }

  .star-bookmark:active {
    color: #b18636;
  }

  .cash-out-icon {
    width: 13px;
    height: 13px;
    line-height: 13px;
    font-size: 10px;
    margin-right: 7px;
    border-radius: 20px;
    color: $asset2;
    border: 2px solid $asset2;
    margin-top: 5.8px;
  }

  .market-item-open-close-arrow {
    width: 35px;
    border-left: 1px solid #09090e;
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
    color: #68686A;

    .up {
      transform: rotate(90deg);
    }

    .down {
      transform: rotate(-90deg);
    }
  }

  .market-item-open-close-arrow:hover {
    background-color: $themeColor4;
  }

  .large-market-element-item-prices {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 0.5px);

    .large-market-info-item {
      display: flex;
      width: 100%;
      float: left;
      font-size: 13px;
      padding: 8px 0 8px 0;
      justify-content: space-between;
      border-left: 1px solid #0d0d13;
      border-top: 1px solid #0d0d13;
      background: $themeColor4;

      p {
        font-size: 12px;
        font-weight: bold;
      }

      &.active {
        .large-market-info-item-type {
          color: $themeColorSelectionActive !important;
        }

        .large-market-info-item-price {
          color: $themeColorOddActive !important;
        }
      }

      &:hover {
        .large-market-info-item-type {
          color: $themeColorSelectionHover !important;
        }
      }
    }
  }

  .large-market-info-item-type {
    color: $themeColorSelection !important;
    font-size: 12px;
    font-weight: bold;
    margin-left: 8px;
  }

  .price {
    &:hover {
      background-color: $themeColor5;
      cursor: pointer;
    }

    &.active, &.active:hover {
      color: white;
      background-color: $selectionHighlight;
    }
  }

  .large-market-info-item-price {
    color: $themeColorOdd !important;
    margin-right: 8px;
  }

  .match-running-info {
    width: 100%;
    float: left;
    font-size: 14px;
    margin-top: 20px !important;
    color: #898c99;
  }
}