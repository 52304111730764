@import "../../styles/variables";

.promotion-block {
  width: 100%;
  min-height: 100%;
  display: flex;
  position: absolute;
  justify-content: center;
  z-index: 30;
  background: #000326;

  &.instant-view {
    overflow-y: hidden;

    .slider {
      filter: blur(3px);
      -webkit-filter: blur(3px);
    }
  }

  .promotion-block-content {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .slider {
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    position: absolute;

    img {
      width: 100%;
      border-radius: 2px;
    }
  }

  .promotion-content {
    width: 92%;
    display: flex;
    margin-top: 23%;
    flex-direction: column;
    user-select: none;

    .promotion-filters {
      height: fit-content;
      flex-direction: row;
      font-size: 14px;
      margin-top: 25px;
      margin-bottom: 2vw;
      background-color: rgba(169, 190, 255, 0.1);
      border-bottom: 3px solid $promotionLine;
      padding: 1.5vw 0 1.5vw 2vw;
      border-radius: 3px;
      z-index: 1;

      .promotion-tab {
        float: left;
        font-weight: bold;
        width: 17%;
        height: 31px;
        font-size: 13px;
        line-height: 31px;
        margin-right: 20px;
        padding-left: 12px;
        border-radius: 5px;
        background-color: rgba(26, 40, 76, 0.5);
        box-shadow: 0 0 4px #a7a7a7bd, 0 0 5px $asset1;
        text-shadow: 0 0 4px #5b5b5b, 0 0 5px $asset1;
        cursor: pointer;

        &.active {
          background-color: rgba(9, 16, 48, 0.6);
          cursor: default;

          &:hover {
            background-color: rgba(9, 16, 48, 0.6);
          }
        }

        &:hover {
          background-color: rgba(26, 40, 76, 0.7);
        }
      }

      .custom-select, .matchId-input {
        width: 16%;
        float: right;
        height: 35px;
        margin-right: 20px;
        background-color: rgba(9, 16, 48, 0.4);
        box-shadow: 0 0 4px #c05ef8, 0 0 4px #37406f;

        select {
          height: inherit;
          color: #dae0ec;
          background-color: rgba(9, 16, 48, 0.4);
          text-shadow: 0 0 3px $asset1, 0 0 3px #37406f;
          font-size: 13px;

          &:hover {
            background-color: rgba(9, 16, 48, 0.2);
          }
        }

        &:after {
          background-color: rgba(9, 16, 48, 0.2);
          line-height: 45px;
        }

        &:hover {
          &:after {
            background-color: rgba(9, 16, 48, 0.2);
          }
        }
      }
    }

    .promotion-items {
      z-index: 1;
      display: flex;
      justify-content: center;
      background-color: rgba(169, 190, 255, 0.1);
      margin-bottom: 25px;
      border-radius: 4px;

      .items-container {
        margin-top: 2%;
        margin-bottom: 0.5%;
        display: flex;
        width: 96%;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: baseline;

        .item {
          margin-bottom: 2vw;
          position: relative;
          justify-content: center;
          cursor: pointer;
          border-radius: 4px;

          video {
            width: 20vw;
            height: 11.3vw;
            -webkit-animation: fadein 0.5s ease-in alternate;
            -moz-animation: fadein 0.5s ease-in alternate;
            animation: fadein 0.5s ease-in alternate;
            border-radius: 4px;
          }

          .new-icon {
            position: absolute;
            width: 5vw;
            height: auto;
            left: -0.3vw;
            top: -0.25vw;
          }

          img {
            width: 100%;
            height: 13vw;
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
            -webkit-animation: fadein 0.5s ease-in alternate;
            -moz-animation: fadein 0.5s ease-in alternate;
            animation: fadein 0.5s ease-in alternate;
          }

          .gif-item {
            animation-play-state: running;
          }

          &:after {
            content: "";
            display: block;
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            background: url('./play.png') no-repeat center;
            background-size: 26%;
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
            pointer-events: none;
          }

          .game-name {
            width: 100.05%;
            height: 33px;
            color: #eae3e3;
            margin-top: -38.5px;
            position: absolute;
            text-align: center;
            line-height: 33px;
            font-weight: bold;
            font-size: 14px;
            background: rgba(3, 3, 3, 0.5);
            border-bottom-right-radius: 3px;
            border-bottom-left-radius: 3px;
            border-top: 2px solid rgba(3, 3, 3, 0);
          }

          &:hover {
            opacity: 1;

            &:after {
              opacity: 1;
              transition: opacity 0.3s ease-in-out;
            }

            .game-name {
              //margin-top: -34.5px;
              border-top: 2px solid $gameHighlight;
            }

            @keyframes fadein {
              from {
                opacity: 0
              }
              to {
                opacity: 1
              }
            }

            /* Firefox < 16 */
            @-moz-keyframes fadein {
              from {
                opacity: 0;
              }
              to {
                opacity: 1;
              }
            }

            /* Safari, Chrome and Opera > 12.1 */
            @-webkit-keyframes fadein {
              from {
                opacity: 0;
              }
              to {
                opacity: 1;
              }
            }

            /* Internet Explorer */
            @-ms-keyframes fadein {
              from {
                opacity: 0;
              }
              to {
                opacity: 1;
              }
            }

            /* Opera < 12.1 */
            @-o-keyframes fadein {
              from {
                opacity: 0;
              }
              to {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}