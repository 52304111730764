.video-scene {
  display: flex;
  flex-direction: column;
  flex-flow: wrap;
  justify-content: center;
  z-index: 1;
  animation: fadeIn 0.2s ease-in;
  position: relative;
  background: rgba(7, 17, 37, 0.7);

  .tennis-players-block {
    display: flex;
    align-items: center;
    width: 100%;
    height: 25%;
    -webkit-box-shadow: inset 0 0 14px #040610;
    -moz-box-shadow: inset 0 0 14px #040610;
    box-shadow: inset 0 0 14px #040610;
    cursor: default;

    .player-block-inst {
      margin-top: 0;

      &-box {
        width: 30%;
      }

      .player-icon {
        top: 8%;
        width: 100%;
        position: inherit;
        height: auto;
      }

      .name {
        bottom: -5%;
        left: 57%;
      }

      .ball {
        bottom: 0.7vw;
      }

      .player-star {
        bottom: -1.7vw;
        left: 25%;
      }
    }
  }

  .ti-video-player-block {
    width: 100%;
    height: 53%;
    display: flex;
    justify-content: center;

    .player-container {
      width: 50%;
      height: 100%;
      position: relative;

      video {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }

      &:hover .video-controls {
        display: flex;
      }

      .video-controls {
        display: none;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        width: 100%;
        bottom: 10px;
        right: 3%;

        .video-quality {
          bottom: 28px;
          position: absolute;

          .high-quality {
            display: flex;
          }

          .quality-icon {
            cursor: pointer;
          }

          .selected-quality {
            position: absolute;
            left: -12px;

            &.hd {
              top: 7px;
            }

            &.sd {
              bottom: 3px;
            }
          }
        }

        .control-icon {
          width: 20px;
          height: 20px;
          cursor: pointer;
          margin-right: 10px;
        }

        .setting-icons {
          position: relative;

          .setting {
            width: 20px;
            height: 20px;
            margin-left: 5px;
            cursor: pointer;
          }

          .hd-icon {
            position: absolute;
            top: 0;
            right: -5px;
          }
        }

        .video-volume {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-right: 5px;

          .video-volume-instant {
            width: 60px;
            height: 3px;
            border-radius: 5px;
            background: #d3d3d3;
            outline: none;
            opacity: 0.7;
            transition: opacity 0.2s;
            -webkit-appearance: none;

            &:hover {
              opacity: 0.8;
            }

            &::-webkit-slider-thumb {
              -webkit-appearance: none;
              appearance: none;
              width: 11px;
              height: 11px;
              border-radius: 50%;
              background: white;
              cursor: pointer;
            }

            &::-moz-range-thumb {
              width: 11px;
              height: 11px;
              border-radius: 50%;
              background: white;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .ti-video-scene-footer {
    height: 12%;
    width: 40%;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .button {
      min-width: 13vw;
      font-size: 1.05vw;
      margin-left: 0.7%;
      margin-right: 0.7%;
    }
  }
}
