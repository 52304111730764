.cash-out-pop-up {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  font-weight: bold;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  animation: fadeIn 0.13s linear;
  z-index: 20;
  cursor: default;
  box-shadow: 2px 2px 8px #000000;

  .cash-out-pop-up-container {
    width: 380px;
    height: 250px;
    flex-direction: column;
    display: flex;
    margin-top: 150px !important;
    margin-left: auto;
    margin-right: auto;
    background: #20202b;
    font-size: 13px !important;

    .container {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: 5px;
      font-size: 12px;
      color: #bdc0ca;

      .total-cash-out {
        font-size: 20px;
      }

      .cash-out-button {
        width: 70%;
        height: 33px;
        line-height: 33px;
        color: #d2d2d2;
        margin-bottom: 10px;
        background-color: #1e8559;
        cursor: pointer;

        &:hover {
          background-color: #208c5e;
        }

        &:active {
          background-color: #1c8057;
        }

        &.cancel {
          background-color: #464958;
          margin-bottom: 2px;
        }
      }

      .cash-out-warning {

        color: #e22222;
      }
    }
  }
}