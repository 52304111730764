@import "../../styles/variables";
$filterSize: 5px;

.viewport-container {
  display: flex;
  justify-content: center;
  margin-top: 17px;
  margin-bottom: 20px;
  min-height: 50%;

  .games-slider {
    display: flex;
    justify-content: center;
    position: absolute;
    margin-top: 3%;
    margin-bottom: 7%;
    max-width: 66%;

    .slide-arrow {
      width: 100%;
      position: relative;
      text-align: center;
      display: grid;
      border-radius: 12px;
      cursor: pointer;
      box-shadow: 0 0 15px #000000;

      animation: fadein 0.35s ease-in alternate;
      -webkit-animation: fadein 0.35s ease-in alternate;
      -moz-animation: fadein 0.35s ease-in alternate;

      &.arrow-to-left {
        margin-right: 10px;
      }

      &.arrow-to-right .slide-img {
        animation: 0.5s ease-in 0s 1 normal none running fadeInLeft
      }

      &.arrow-to-left .slide-img {
        animation: 0.5s ease-in 0s 1 normal none running fadeInRight
      }

      // ANIMATIONS LIST //
      // fadeIn, fadeInUp, fadeInDown, fadeInRight, fadeInLeft ,bounceIn, bounceInUp, bounceInRight, bounceInDown,
      // bounceInLeft, flipInX, zoomIn, blurIn, blurInUp, blurInRight, blurInDown, blurInLeft

      /*.slider-arrow-footer {
        width: 100%;
        height: 40px;
        background: $themeColor2;
        position: absolute;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        bottom: 0;
        display: flex;
        flex-direction: row;

        animation: 0.5s ease-in 0s 1 normal none running fadeIn;

        .slider-footer-competition-name {
          width: 100%;
          height: 22px;
          margin-left: 8px;
          border: 1px solid $themeColor5;
          border-radius: 5px;
          color: #9c9cac;
          margin-top: 8px;
          cursor: default;
          font-weight: bold;
          font-size: 13px;
        }

        .slider-footer-results-button {
          white-space: pre;
          font-size: 12px;
          height: 28px;
          line-height: 24px;
          margin-right: 8px;
          margin-top: 8px;
          margin-left: 12px;
          padding-left: 15px;
          padding-right: 14px;
          border-radius: 5px;
          color: white;
          border: none;
          outline: none;
          cursor: pointer;
          font-weight: bold;
          text-decoration: none;
          background-color: $asset1;
          box-shadow: inset 0 0 0, inset -2px -5px 4px rgba(0, 0, 0, 0.2);
        }
      }*/

      img {
        width: inherit;
        height: inherit;
        opacity: 0.7;
        border-radius: 12px;
        transition: opacity .25s ease-out;
        -moz-transition: opacity .25s ease-out;
        -webkit-transition: opacity .25s ease-out;
        -o-transition: opacity .25s ease-out;

        filter: blur(1.5px);
        -webkit-filter: blur(1.5px);

        &:hover {
          filter: blur(0px);
          -webkit-filter: blur(0px);
          opacity: 1;
        }

        &.arrow-icon {
          filter: blur(0px);
          -webkit-filter: blur(0px);
        }
      }

      .arrow-icon {
        width: 15%;
        top: 30%;
        height: auto;
        border-radius: 0;
        opacity: 0.5;
        position: absolute;
        pointer-events: none;
        animation: 1.8s ease-in 0s 0.5 normal none running fadeIn;

        &.arrow-right {
          right: 8%;
        }

        &.arrow-left {
          left: 8%;
          -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
        }
      }
    }

    .arrow-icon-layer img {
      border-radius: 0;
    }
  }
}