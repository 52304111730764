$controlsSize: 35px;

.instant-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.3);
  animation: fadeIn 0.3s ease-in;

  .instant-viewport {
    width: 77%;
    height: 98%;
    background-color: #969db0;
    position: relative;
  }

  .controls {
    width: $controlsSize;
    right: 0;
    position: absolute;
    font-size: 13px;
    z-index: 1;
    font-weight: bold;

    .close-button {
      width: 100%;
      text-align: center;
      line-height: $controlsSize;
      height: $controlsSize;
      cursor: pointer;

      &:hover {
        font-size: 20px;
      }
    }
  }

  .viewport-content {
    width: 100%;
    height: 100%;
  }
}