@import "../../../styles/variables";

.bet-item {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: calc(100% - 14px);
  margin: 6px auto auto;
  font-size: 12px;
  background-color: $themeColor4;
  cursor: default;
  color: #c0c0cb;
  border-radius: 2px;
  animation: fadeIn 0.25s linear;
  font-weight: bolder;
  user-select: none;

  .bet-input-container {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .stake-block {
      width: 96%;
      display: flex;
      margin-top: 5px;
      margin-bottom: 5px;
      justify-content: space-between;

      input {
        width: 35%;
        box-sizing: border-box;
        background: #4B505E;
        border: none;
        height: 28px;
        float: left;
        font-size: 12px;
        line-height: 32px;
        color: #e5e5e5;
        padding: 0 4px;
        outline: none;
        text-align: center;
        font-weight: bold;

        &:focus {
          background: #414652;
        }
      }

      .stake-button {
        width: 9%;
        background: #7B7F8A;
        border-radius: 2px;
        text-align: center;
        line-height: 28px;
        height: 28px;
        cursor: pointer;
        color: white;
        padding: 0 2px;
        font-size: 10px;

        &:hover {
          opacity: 0.9;
        }

        &:active {
          opacity: 0.8;
        }

        &.minus {
          width: 6%;
          font-size: 12px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          margin-right: -1.7%;
        }

        &.plus {
          width: 6%;
          font-size: 12px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          margin-left: -1.8%;
        }

        &.max-button, &.min-button {
          width: 12%;
        }
      }
    }

    .max-bet-button {
      float: left;
      width: 28%;
      height: 30px;
      line-height: 30px;
      font-size: 12px;
      font-weight: bold;
      background-color: $maxBet;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
      cursor: pointer;
      color: white;

      &:hover {
        background-color: $maxBetHover;
      }

      &:active {
        background-color: $maxBetActive;
      }
    }
  }

  .possible-win, .win-info {
    display: flex;
    width: 96%;
    margin-left: 1%;
    color: #c1c1c1;
    font-weight: normal;
    font-size: 12px;
    justify-content: space-between;
    white-space: nowrap;
    overflow: hidden;
    padding-bottom: 2px;
    font-weight: b;
  }

  .tax-info {
    border-top: 1px solid #404556;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0
    }
    100% {
      opacity: 1
    }
  }

  p {
    margin: 4px 0 4px 5px;
    height: 15px;
    float: left;
    text-align: left;
  }

  .bet-item-header {
    display: block;
    position: relative;
    flex-direction: row;
    width: 98%;
    margin-left: 1%;
    padding-top: 3px;

    .checkbox {
      float: left;
      margin-top: 6px;
      margin-left: 6px;
      background: #2b2f40;
    }

    .remove-item {
      cursor: pointer;
      position: absolute;
      top: 5px;
      right: 5px;

      &:hover {
        color: white;
      }
    }

    .bet-failed-icon {
      width: 10px;
      height: 10px;
      float: left;
      margin-left: 6px;
      margin-top: 7px;
      border-radius: 20px;
      background-color: #e00909;
    }
  }

  .bet-type {
    text-align: left;
    width: 96%;
    margin-left: 2.8%;
    margin-top: 2px;
    margin-bottom: 2px;
    color: #b7bdcd;
    font-weight: normal;
  }

  .bet-item-footer {
    width: 96%;
    display: block;
    margin-left: 1%;
    font-size: 12px;
    padding-bottom: 4px;

    .bet-coef {
      float: right;
    }
  }

  .bet-time, .bet-item-footer {
    color: $alpine;
  }
}
