@import "../../../../styles/variables";
$height: 40px;

.live-fight-info {
  width: 100%;
  height: $height;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: $themeColor3;
  margin-bottom: 2px;
  position: relative;

  .live {
    position: absolute;
    left: 0;
    width: fit-content;
    color: #61EF61;
    font-size: 12px;
    margin-left: 15px;
    margin-right: 15px;

    &:before {
      content: " ";
      width: 6px;
      height: 6px;
      margin-right: 8px;
      margin-top: 5px;
      float: left;
      border-radius: 50%;
      background: #61EF61;
      box-shadow: 0 0 0 #61EF61;
      animation: pulse 2s infinite;
    }
  }

  .kg-block {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    width: 54px;
    height: 40px;
    background: $themeColor1;
    margin-right: 0;

    .kg, .kg-value {
      display: flex;
      align-items: center;
      height: 50%;
    }
  }

  .players-info {
    display: flex;
    flex-direction: row;

    .fight-player-block {
      margin-left: 15px;
      margin-right: 10px;
      display: flex;
      font-size: 12px;
      font-weight: bold;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      position: relative;

      .flag-img {
        height: 24px;
        margin: 8px;
      }

      .flag-img, .name, .scores-info {
        z-index: 1;
      }
    }

    .vs-block {
      padding-top: 6px;

      .vs {
        width: 38px;
        height: 27px;
        padding-left: 3px;
        margin-right: 14px;
        margin-left: 14px;
      }
    }

    .scores-info {
      display: flex;
      width: 38px;
      height: 40px;
      justify-content: center;
      align-items: center;
      border-left: 2px solid $themeColor1;
      border-right: 2px solid $themeColor1;

      &.left {
        margin-left: 8px;
      }

      .score {
        z-index: 1;
        font-size: 14px;
      }
    }
  }
}