.tennis-players-block {
    display: flex;
    width: 100%;
    height: 25%;
    -webkit-box-shadow: inset 0 0 12px #070d1c;
    -moz-box-shadow: inset 0 0 12px #070d1c;
    box-shadow: inset 0 0 12px #070d1c;
    margin-bottom: 10px;
    padding: 10px;
    box-sizing: border-box;
    position: relative;

    .player-star {
        position: absolute !important;
        left: 15%;
        bottom: -6px;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            font-size: 1.2vw !important;
        }

        [data-index="2"] {
            font-size: 1.5vw !important;
        }
    }

    .vs-container {
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;

        .score-container {
            width: 10%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 4vw;
            white-space: pre;
            color: #222934;
            text-shadow: 2px 0 0 #aebdcd, -2px 0 0 #aebdcd, 0 2px 0 #aebdcd, 0 -2px 0 #aebdcd, 2px 2px #aebdcd,
                -2px -2px 0 #aebdcd, 2px -2px 0 #aebdcd, -2px 2px 0 #aebdcd;
        }

        .vs {
            height: 40%;
        }
    }

    .player-block-inst {
        position: relative;
        display: flex;
        justify-content: center;
        width: 40%;
        margin-bottom: 2%;

        &-box {
            display: flex;
            align-items: center;
            width: 35%;
            position: relative;

            .tennis-ball-box {
                position: absolute;
                right: -25%;
                bottom: 25%;
               
                .ball {
                    width: 25px;
                    height: 25px;
                    animation: jump 1s infinite ease-in-out;
                }

                .shadow {
                    position: absolute;
                    width: 25px;
                    height: 25px;
                    background-color: rgba(0, 0, 0, 0.2);
                    border-radius: 50%;
                    z-index: 1;
                    filter: blur(2px);
                    animation: shadow 1s infinite linear;
                }

                @keyframes shadow {
                    0% {
                        transform: scale(1);
                    }

                    50% {
                        transform: scale(0.5);
                    }

                    100% {
                        transform: scale(1);
                    }
                }

                @keyframes jump {
                    0% {
                        transform: translateY(-2vw) rotate(0);
                    }

                    50% {
                        transform: translateY(0) rotate(180deg);
                    }

                    100% {
                        transform: translateY(-2vw) rotate(360deg);
                    }
                }
            }
        }

        .player-icon {
            height: 75%;
            position: absolute;
        }

        .name {
            left: 50%;
            bottom: 15%;
        }
    }
}
