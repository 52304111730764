$selectColor: #212531;
$textColor: #7c8292;

.custom-select {
  width: 100%;
  position: relative;
  display: inline-block;
  overflow: hidden;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  font-family: 'Lato', sans-serif;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  select {
    width: 100%;
    display: inline-block;
    float: left;
    color: $textColor;
    font-size: 12px;
    height: 30px;
    border-radius: 4px;
    margin: 0;
    padding-left: 8px;
    outline: none;
    background: $selectColor;
    cursor: pointer;
    border: 0;
    font-weight: normal;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    option {
      font-size: 13px !important;
    }
  }

  &:after {
    width: 10px;
    content: '\276F';
    position: absolute;
    right: 8px;
    font-size: 12px;
    line-height: 30px;
    color: #acaecd;
    pointer-events: none;
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

.no-pointer-events .custom-select:after {
  content: none;
}
