@import "../../../styles/variables";

$lineColor: #424658;

.live-tables {
  display: flex;
  width: 100%;
  font-size: 12px;
  cursor: default;
  margin-bottom: 5px;

  .live-tables-content {
    display: flex;
    width: 100%;

    .live-scores {
      width: 40%;
      margin-right: 3px;

      .live-scores-content {
        width: 100%;
        height: 183px;
        display: flex;
        overflow-y: auto;
        flex-direction: column;
        background-color: $themeColor4;

        .live-score-info {
          display: flex;
          width: 100%;
          height: 45px;
          color: #BBBBBE;
          background-color: $themeColor3;
          border-bottom: 1px solid $themeColor5;
          cursor: pointer;

          &.last-item {
            border-bottom: none;
          }

          &:hover {
            background-color: $themeColor4;
          }

          &.active {
            background-color: $themeColor2;
            color: #d4d4d4;
            pointer-events: none;
          }

          .date-data {
            width: 50px;
            font-size: 11px;
            line-height: 13px;
            margin-top: 9px;
            margin-left: 5px;
            margin-right: 5px;
          }

          .teams-data {
            width: 60%;
            height: 22px;
            text-align: left;
            margin: auto;
            display: flex;

            .container {
              justify-content: space-between;
              display: flex;

              .team-data {
                display: flex;
                float: left;

                &.first {
                  width: 85px;
                  margin-right: 27px;
                }

                p {
                  padding-top: 3px;
                }

                img {
                  height: 20px;
                  margin-right: 8px;
                }
              }
            }
          }

          .score-data {
            display: flex;
            width: 70px;
            justify-content: center;
            margin: 10px 10px auto auto;
            background-color: $themeColor5;
            border-radius: 2px;
            padding: 3px;

            .player1 {
              padding-right: 13px;
              border-right: 1px solid $themeColor5;
            }

            .player2 {
              padding-left: 13px;
              border-left: 1px solid $themeColor5;
            }
          }
        }
      }
    }

    .live-table {
      display: flex;
      flex-direction: column;
      width: calc(60% - 3px);
      color: #b6b6bb;

      .live-table-content {
        display: flex;
        width: 100%;
        height: 183px;
        overflow-y: auto;
        overflow-x: hidden;
        white-space: nowrap;
        flex-direction: row;
        background-color: $themeColor3;

        .live-table-block {
          text-align: left;
          width: 60%;

          .life-table-item {
            display: flex;
            width: 100%;
            height: 22px;
            position: relative;
            padding-left: 8px;
            border-bottom: 1px solid $themeColor5;
            border-right: 1px solid $themeColor5;

            p {
              width: 45px;
              text-align: center;
              display: block;
            }

            .triangle-up {
              float: left;
              top: 9px;
              left: 60px;
              position: absolute;
              border-left: 3.5px solid transparent;
              border-right: 3.5px solid transparent;
              border-bottom: 4.5px solid #7be80f;
            }

            .triangle-down {
              float: left;
              top: 9px;
              left: 60px;
              position: absolute;
              border-left: 3.5px solid transparent;
              border-right: 3.5px solid transparent;
              border-bottom: 4.5px solid #e82e2e;
              transform: rotate(180deg);
              -webkit-transform: rotate(180deg);
            }
          }

          .team-position-title, .team-info-title {
            margin-top: 2px;
            font-weight: bold;
          }

          .team-position-data {
            display: flex;
            position: relative;
            line-height: 22px;

            .team-flag {
              margin-left: 18px;
              margin-right: 20px;
              height: 20px;
            }
          }

          .team-info-block, .team-info-title {
            width: calc(100% - 7px);
            justify-content: space-between;
            border-right: none;
          }

          .table-result {
            display: flex;
            width: 25px;
            line-height: 22px;
            font-weight: normal;
            padding-left: 5px;
            position: relative;
            text-align: center;
            border-right: 1px solid #424658;

            &.title {
              font-weight: bold;
            }
          }
        }
      }
    }

    .live-table-title {
      height: 30px;
      line-height: 30px;
      text-align: left;
      font-weight: bold;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      padding-left: 5px;
      background-color: $themeColor3;
      background: -moz-linear-gradient(top, $themeColor3 0%, $themeColor5 100%);
      background: -webkit-linear-gradient(top, $themeColor1 0%, $themeColor2 100%);
      background: linear-gradient(to bottom,  $themeColor1 0%, $themeColor2 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#121926', endColorstr='#1e2430', GradientType=0);
      border-bottom: 1px solid $themeColor1;
    }
  }

  .live-icon {
    width: 8px;
    height: 8px;
    float: left;
    margin-top: 11px;
    margin-left: 5px;
    margin-right: 8px;
    border-radius: 50%;
    background: #61EF61;
    box-shadow: 0 0 0 #61EF61;
    animation: pulse 2s infinite;
  }

  ::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
  }
}