@import "../../../../styles/variables";

$itemHeight: 40px;

.history-bet-item-container {

  .history-bet-item {
    display: flex;
    height: 40px;
    margin-top: 2px;
    margin-left: 2px;
    margin-right: 2px;
    font-size: 11px;
    background-color: $themeColor4;
    cursor: pointer;

    &:hover {
      opacity: .9;
    }

    .bet-status {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .bet-info-item {
    width: 10%;
    line-height: 40px;

    p {
      float: left;
      margin-top: 10px;
      margin-bottom: 15px;
      line-height: 2px;

      &.id {
        margin-top: 0;
      }
    }

    .date-and-time {
      white-space: pre;
      span {
        display: block;
        margin-top: 12px;
        text-align: center;
      }

    }

    .id-container {
      display: flex;
      justify-content: center;
      width: 100%;

      .id-block {

        .copy-icon {
          float: left;
          width: 18px;
          height: 15px;
          margin-top: -5px;
          margin-left: 5px;

          &:hover {
            opacity: 0.8;
          }

          &:active {
            opacity: 0.5;
          }
        }
      }
    }

    .status-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 2px;
      width: 40px;
      height: 37.5px;
      float: left;
      background-color: $themeColor5;

      p {
        width: 18px;
        height: 18px;
        margin-top: 12px;
        line-height: 18px;
        border: 2px solid #b4b4bc;
        border-radius: 18px;
      }
    }

    .info-icon {
      width: 13px;
      height: 13px;
    }
  }

  .event-info {
    width: 20%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-right: 2px solid #2d343e;
    padding-left: 10px;
    padding-right: 10px;

    .bet-event-info {
      display: flex;
      align-items: center;
      height: 40px;
    }
  }

  .outcome-info-more {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
  }

  .win {
    color: #49ab4b;
  }

  .lost {
    color: #ff2c2c;
  }

  .accepted {
    color: #dddddd;
  }

  .return, .cashouted {
    color: #ffdb59;
  }

  .coef-info {
    border-right: 2px solid #2d343e;

    &.more {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .data-id {
    display: flex;
    width: 15%;
    align-items: center;
    max-height: $itemHeight;
    flex-direction: column;
    text-align: left;

    &.more {
      justify-content: center;
    }
  }

  .bet-type {
    width: 40%;

    p {
      line-height: 14px;
    }

    .bet-type-name {
      float: left;
      padding-left: 10px
    }
  }

  .bet-stake, .odd-type, .odds, .outcome {
    margin-left: 0;
    padding-left: 10px;
    padding-right: 10px;
    background-color: $themeColor5;
    border-right: 2px solid #1c1f24;
  }

  .outcome {
    width: 13%;
    position: relative;

    .info-icon {
      position: absolute;
      right: 10px;
      top: 13px;
    }
  }

  .odds {
    padding-left: 5px;
    padding-right: 5px;
  }

  .status {
    flex: 5;
  }

  .bet-item-more-info {
    display: flex;
    height: $itemHeight;
    margin-left: 2px;
    margin-right: 2px;
    font-size: 11px;
    border-top: 2px solid #1c1f24;
    border-bottom: 1px solid $alpine;
    background-color: $themeColor1;
    cursor: default;

    .status-icon {
      width: 39px;
      margin-left: -3px;

      p {
        border: none;
        font-size: 13px;
      }
    }

    .bet-type {
      width: 35%;
      border-right: 2px solid #2d343e;

      .game-info {
        display: flex;
        justify-content: space-between;
        padding-right: 10px;
      }
    }

    .bet-stake, .odd-type, .outcome {
      background-color: $themeColor1;
    }
  }
}
