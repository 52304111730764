@import "../../styles/variables";

$tabWidth: 110px;

.tab-button {
  width: $tabWidth;
  height: $providersBlockHeight;
  float: left;
  text-align: center;
  vertical-align: middle;
  line-height: $providersBlockHeight;
  cursor: pointer;
  background-color: $themeColor4;
  -webkit-transition: background-color 0.2s ease-out;
  -moz-transition: background-color 0.2s ease-out;
  -o-transition: background-color 0.2s ease-out;
  transition: background-color 0.2s ease-out;

  &.active {
    background-color: $asset1;
  }
}

.tab-button > img {
  height: 11px;
}

.tab-button:hover {
  background-color: $asset1;
  color: white;
}