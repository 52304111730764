@import "../../styles/variables";

$fontColor: #d3d3d9;

.results-pop-up {
  position: fixed;
  cursor: default;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.7);
  color: $fontColor;
  animation: fadeIn 0.13s linear;
  z-index: 1;

  .custom-select {
    position: relative;
    select {
      background: $themeColor2;
      color: #d3d3d9;
    }
  }

  .results-loader {
    width: 60px;
    height: 60px;
    display: block;
    position: absolute;
    top: 50%;
    left: 48%;
    border-radius: 50%;
    border-top: 3px solid $asset1;
    border-right: 3px solid transparent;
    background: transparent;
    -webkit-animation: 0.5s spin linear infinite;
    animation: 0.5s spin linear infinite;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.pop-up-inner {
  width: 80%;
  height: 75%;
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
  background: $themeColor3;
}

.pop-up-header {
  height: 50px;
  background-color: $themeColor2;
  text-underline: none;
  border-bottom: 2px solid $resultsLine;

  span {
    display: block;
    line-height: 50px;
    margin-left: 15px;
    font-size: 14px;
    float: left;
    font-weight: bold;
  }

  .pop-up-close {
    width: 18px;
    height: 18px;
    position: relative;
    display: block;
    float: right;
    cursor: pointer;
    right: 18px;
    top: 18px;
    cursor: pointer;
  }

  .pop-up-close:hover {
    opacity: 0.8;
  }

  .pop-up-close:before,
  .pop-up-close:after {
    position: absolute;
    content: " ";
    height: 16px;
    width: 2px;
    background-color: $themeColor5;
  }

  .pop-up-close:before {
    transform: rotate(45deg);
  }

  .pop-up-close:after {
    transform: rotate(-45deg);
  }
}

.pop-up-search-header {
  display: flex;
}

.pop-up-search-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  margin-left: 10px;
  font-size: 13px;
  font-weight: bold;
}

.search-options {
  display: flex;
  width: 100%;
  font-weight: bold;

  .search-option {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 10px 10px 5px 5px;

    &:first-child {
      margin-right: 0;
    }

    &:last-child {
      margin-right: 15px;
    }

    &.no-title {
      margin-top: 25px;
    }
  }
}

.search-option-title {
  margin-bottom: 10px;
  padding-left: 2px;
}

.untitled {
  margin-top: 22px;
}

.pop-up-content {
  height: 80%;
}

.pop-up-results-container {
  width: 100%;
  height: 100%;
  background-color: $themeColor2;
  overflow: auto;
  font-size: 13px;
  font-weight: bold;
}

.game-result-items {
  width: 99.8%;
  height: 27px;
  display: flex;
  flex-direction: row;
  background: $themeColor3;
  border-bottom: 1px solid #181c23;
}

.result-titles {
  background: $themeColor2;
}

.sort-by-date {
  cursor: pointer;
}

.sort-by-date-arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;

  .to-down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
}

.game-results-list.matchIdResults {
  display: flex;

  > div {
    width: 50%;
  }

  .result-info {
    width: auto;
    margin-right: 5px;
  }

  span {
    color: #aa8136;
  }
}

.result-title {
  width: 37.5%;
  line-height: 24px;
  border-left: 1px solid $themeColor1;
  text-align: left;
  padding-left: 10px;

  &.sort-by-date {
    width: 25%;
  }
}

.result-info {
  width: 37.5%;
  line-height: 24px;
  border-left: 1px solid $themeColor1;
  text-align: left;
  padding-left: 10px;

  &.date {
    width: 25%;
  }
}

.game-result-item {
  width: inherit;
  height: 25px;
  border-bottom: 1px solid $themeColor1;
}

.search-calendar {
  position: relative;
  display: flex;
  width: 100%;
  height: 30px;
  background-color: $themeColor2;
  border-radius: 5px;
  line-height: 30px;
  text-align: left;
  padding-left: 10px;
  justify-content: space-between;

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 107%;

    input {
      width: 100%;
      outline: none;
      font-weight: bold;
    }
  }

  .react-datepicker-popper {
    margin-top: 3px !important;
    left: -10px !important;
  }

  .react-datepicker__triangle {
    visibility: hidden;
  }

  .react-datepicker__month-container {
    border-radius: 5px;
  }

  .react-datepicker,
  .react-datepicker__header {
    border: none;
    background-color: $themeColor2;
    color: white;
  }

  .react-datepicker__day,
  .react-datepicker__current-month,
  .react-datepicker__day-name {
    color: #969db0;
    border-radius: 0;
  }

  .react-datepicker__day--disabled {
    color: #494e5c;
  }

  .react-datepicker__day:hover {
    background-color: #2e323e;
    border-radius: 0;
  }

  .react-datepicker__day--keyboard-selected{
    background-color: unset;
  }
  
  .react-datepicker__day--selected {
    background-color: #0d1013;
  }
}

.disable-input {
  position: absolute;
  background: #1e222d85;
  width: 100%;
  height: 100%;
  z-index: 1;
  left: 0;
}

.matchId-input {
  height: 30px;
  padding: 0;
  outline: none;
  border-radius: 4px;
  padding-left: 11px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.calendar-input, .matchId-input {
  width: 100%;
  border: none;
  background-color: $themeColor2;
  color: $fontColor;
  cursor: pointer;
}

.calendar-icon {
  width: 16px;
  height: 16px;
  float: left;
  margin-right: 8px;
  margin-top: 6px;
  pointer-events: none;
  z-index: 10;
}

.search-results-button {
  height: 30px;
  color: white;
  font-size: 12px;
  background-color: $asset2;
  margin-top: 47px;
  margin-right: 15px;
  margin-left: 6px;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
  border-radius: 4px;
  line-height: 30px;
  white-space: pre;
}

.search-results-button:hover {
  opacity: 0.9;
}

.search-results-button:active {
  opacity: 0.6;
}

// SCROLLBAR

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
}

::-webkit-scrollbar-track {
  background: $themeColor1;
}

::-webkit-scrollbar-thumb {
  background: #373c45;
  border-radius: 0;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
