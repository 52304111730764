.tennis-instant-game {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #969db0;
  position: relative;
  justify-content: center;
  align-items: center;

  .instantLoading {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 111;
    width: 100%;
    height: 100%;
    background-color: #00000047;
    top: 0;
    left: 0;

    .app-loader {
      position: initial;
    }
  }

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 0;
  }

  .button {
    min-width: 14vw;
    height: 2.8vw;
    font-size: 1.3vw;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    background: rgba(119, 119, 119, 0.7);
    border: 3px solid rgba(255, 255, 255, 0);
    text-shadow: 0 1px 4px #1d1d1d;
    user-select: none;
    color: white;
    box-shadow: 0 0 10px 0 #3e3e3e inset;
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;

    &:hover {
      filter: blur(0.1px);
      color: #55e7d7;

      box-shadow: 0 0 3px 0 #6ebab1 inset, 0 0 10px 2px #6ebab1;
      border: 3px solid #6ebab1;
    }

    &:active {
      background: rgba(67, 67, 67, 0.5);
    }
  }
}
