@import "../../../styles/variables";

$borderRadius: 3px;
$stadiumBlockHeight: 20px;

.select-matchday {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: $themeColor4;
  border-radius: 3px;
  margin-bottom: 4px;

  .matchday-container {
    p {
      font-size: 13px;
      font-weight: bold;
      text-align: left;
      float: left;
      margin-top: 12px;
      margin-left: 12px;
      cursor: default;
      padding-bottom: 5px;
      line-height: 35px;
    }

    .matchday-addition-info p {
      margin-left: 2px;
      font-size: 12px;
      padding: 0;
    }

    .matchday-list {
      float: right;
      margin-right: 10px;
      margin-top: 18px;
      margin-bottom: 18px;

      .matchday-button {
        width: 22px;
        height: 23px;
        font-size: 12px;
        line-height: 23px;
        background-color: #dedee3;
        cursor: pointer;
        text-align: center;
        float: left;
        margin-right: 4px;
        border-radius: 2px;
        color: #1c1c2a;
        font-weight: bolder;
      }

      .matchday-button:hover {
        background-color: white;
      }

      .active-round, .active-round:hover, .active-round:active {
        background-color: $asset1;
        color: white;
        cursor: default;
      }
    }
  }

  .stadium-name {
    display: flex;
    float: left;
    width: 100%;
    height: $stadiumBlockHeight;
    border-top: 1px solid #151921;
    background-color: $themeColor2;
    border-top: 2px solid $asset2;
    border-bottom-right-radius: $borderRadius;
    border-bottom-left-radius: $borderRadius;

    p {
      margin: 0;
      padding-left: 10px;
      float: left;
      font-size: 13px;
      line-height: $stadiumBlockHeight;
    }
  }

  .disabled, .disabled:hover {
    color: #656565;
    background-color: #a8a8b6;
    cursor: default;
  }
}