.bet-information {
    padding: 0 10px 10px 10px;
    height: calc(100% - 25%);
    box-sizing: border-box;
    
    .tabs {
        display: flex;
        justify-content: space-between;

        .button {
            width: 49.7%;
            height: 2vw;
            font-size: 1.1vw;
            border: 3px solid #50606d;
            box-shadow: 0 0 7px 0px #425968;
            background-color: #77777747;
            letter-spacing: 1px;
            
            &:hover {
                color: inherit;
            }

            &.active {
                color: #55e7d7;
                box-shadow: 0 0 3px 2px #93a7b5;
            }
        }
    }
}
