@import "variables";
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  color: white;
  background-color: $mainBackgroundColor;

  * {
    font-family: 'Lato Regular', sans-serif !important;
  }

  // Restyle the ToolTip component
  .__react_component_tooltip {
    color: #c0c5d0;
    padding: 3px 8px 5px;
    background-color: #1b1e28;
  }
}

// Fonts

@font-face {
  font-family: 'Lato Regular';
  src: local('Lato Regular'), local('Lato-Regular'), url('./fonts/latoregular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Lato Medium';
  src: local('Lato Medium'), local('Lato-Medium'), url('./fonts/latomedium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Lato Semibold';
  src: local('Lato Semibold'), local('Lato-Semibold'), url('./fonts/latosemibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Lato Bold';
  src: local('Lato Bold'), local('Lato-Bold'), url('./fonts/latobold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Lato Heavy';
  src: local('Lato Heavy'), local('Lato-Heavy'), url('./fonts/latoheavy.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Lato Black';
  src: local('Lato Black'), local('Lato-Black'), url('./fonts/latoblack.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto-Bold-webfont'), local('Roboto-Bold-webfont'), url('./fonts/Roboto-Bold-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
// Animation

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgb(124, 188, 59);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgb(72, 134, 55);
    box-shadow: 0 0 0 0 rgb(110, 141, 75);
  }
  70% {
    -moz-box-shadow: 0 0 0 6px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 6px rgba(204, 169, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}